.logo {
  width: 40%;
  margin: 0px 0px 30px 35px;

  @media (--viewportSmall) {
    width: 207px;
    margin: 0px 10px 0px 35px;
  }
}


@custom-media --viewportSmall (min-width: 550px);
@custom-media --viewportMedium (min-width: 768px);
@custom-media --viewportLarge (min-width: 1024px);
@custom-media --viewportLargeWithPaddings (min-width: 1128px);
@custom-media --viewportXLarge (min-width: 1921px);
