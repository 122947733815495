@import '../../marketplace.css';

.wraper {
    background-color: white;
    margin-bottom: 80px;
    @media (--viewportMedium) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        font-size: 80px;
    }
}

.sections {
    padding: 20px;
    @media (--viewportMedium) {
        padding: 30px;
    }
}

.sections:nth-of-type(2) {
    background-color: #f5f5f5;
}

.sections:nth-of-type(3) {
    background-color: #f0f0f0;
}

.lockIcon {
    opacity: 0.8;
    font-size: 24px;
}

.subtitle {
    margin: 0;
    font-size: 18px;
    font-weight: 800;
    line-height: 1;
}

.title {
    font-size: 36px;
    font-weight: 800;
    margin: 0;
    line-height: 1.2;
}

.icon {
    max-width: 80px;
}

.iconContainer {
    margin-bottom: 10px;
}

.subtitle,
.title,
.description,
.texSeparator,
.iconContainer {
    text-align: center;
}

.description {
    font-size: 18px;
}

.texSeparator {
    font-size: 12px;
    font-weight: 800;
    margin-bottom: 5px;
    color: #666;
}

.check {
    flex: 0 0 15px;
    margin-right: 12px;
    color: var(--marketplaceColor);
}

.itemList {
    display: flex;
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 800;
}

.list {
    margin: 0;
}

.price {
    position: relative;
    text-align: center;
    font-weight: 800;
    font-size: 32px;
    font-family: Galano Grotesque;
    left: -8px;
    margin-top: 60px;
    margin-bottom: 30px;
}

.currency {
    font-size: 26px;
}

.buttonContainer {
    text-align: center;
}

.backToPlans {
    @apply --marketplaceButtonStylesHiddenSmall;
}

.titleChoice {
    margin: 0 0 30px 0;
    line-height: 1.2;
    font-size: 32px;
}

.choiceDescription {
    margin-top: 25px;
    font-size: 18px;
}

.stripeElemWrapper,
.cardinputName {
    border: none;
    background: white;
    border: 1px solid #cecece;
    border-radius: 4px;
    padding: 10px 10px 10px 10px;
}

.formElementsWrapper {
    margin-bottom: 12px;
    width: 80%;
}

.cardBrandIcon {
    width: 38px;
    margin: 1px;
    border: 1px solid #e6e6e6;
}

.cardIconContainer {
    margin: 3px 0 8px 0;
}

.paybutton > button {
    max-width: 300px;
    width: 100%;
    margin: 30px auto;
}

.disclaimer {
    font-size: 14px;
    margin-bottom: 0;
    text-align: center;
}
