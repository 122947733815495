@import '../../marketplace.css';

.content {
  @media (--viewportMedium) {
    margin: 32px auto 0 auto;
    max-width: 564px;
  }

  @media (--viewportLarge) {
    margin: 0;
  }
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: none;
}

.title {
  margin: 0;
  @media (--viewportMedium) {
    margin-bottom: 48px;
  }
}

.unsubscribeButton{
  @apply --marketplaceButtonStylesSecondarySmall;
}

.unsubscribeButtonModal{
  @apply --marketplaceButtonStylesSecondary;
  width: 100%;
  @media (--viewportMedium) {
    width: auto;
  }
}

.unsubscribeButtonCancel{
  @apply --marketplaceButtonStylesHidden;
  width: 100%;
  margin-bottom: 20px;
  @media (--viewportMedium) {
    margin-bottom: 0;
    width: auto;
  }

}
.modalUnsuscribeText{
  margin-top: 30px
}
.modalButtons{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.infoListing{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.titleCard{
  margin: 0 0 10px 0
}

.imageSubscription{
  width: 100%
}

.detailsInfo{
  border-radius: 0 0 3px 3px;
  padding: 20px;
  background: #ededed
}

.infoListingContainer {
  display: flex;
  flex-flow: row wrap;
}

.infoListingCard {
  padding: 20px;
}