.planCardsMobile {
    border-radius: 14px;
    /* display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem; */
    position: relative;

    @media (--viewportLargeWithPaddings) {
        display: none;
    }
}

.planCardsDesktop {
    display: none;
    @media (--viewportLargeWithPaddings) {
        border-radius: 14px;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 40px;
        position: relative;
        margin-bottom: 0px;
        justify-items: center;
    }
}

.plansContainer {
    text-align: center;
    background-color: #f8f8f8;
    padding: 20px;
}

.plansContainer > :nth-child(1) {
    text-align: center;
    margin-bottom: 0px;
    color: #242424;
    font-size: 30px;
    margin-bottom: 10px;
}

.plansContainer > :nth-child(2) {
    margin-top: 5px;
    font-size: 18px;
    color: #242424;
    margin-bottom: 50px;
}

.planCards > div > div > div > ul > li {
    width: 330px !important;
}

.carouselNextButton,
.carouselBackButton {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 0;
    padding: 0 10px;
    transition: all 0.2s ease-in;
    outline: 0;
    cursor: pointer;
    opacity: 0.7;
    /* background: #f1f1f1; */
}

.carouselNextButton:hover,
.carouselBackButton:hover {
    background-color: rgba(255, 255, 255, 0.2);
    transition: all 0.2s ease-in;
    opacity: 1;
}

.carouselNextButton:active,
.carouselBackButton:active {
    background-color: rgba(255, 255, 255, 0.5);
    opacity: 1;
}

.carouselNextButton {
    right: 0px;
}

.carouselBackButton {
    left: 0px;
}

.carouselChevron {
    font-size: 40px;
    color: gray;
}

.planCardOrg {
    background-color: white;
    border-radius: 10px;
    box-shadow: 3px 3px 12px -7px rgb(0 0 0 / 63%);
    padding: 20px;
    text-align: center;
    max-width: 320px;

    @media (--viewportSmall) {
        /* width: 310px; */
        display: grid;
        align-items: center;
        margin: 0px 10px;
        margin-bottom: 60px;
    }
}

.planCardOrg > img {
    margin: auto;
}

.planCard {
    background-color: white;
    border-radius: 10px;
    box-shadow: 3px 3px 12px -7px rgb(0 0 0 / 63%);
    padding: 10px;
    text-align: center;
    max-width: 378px;
    width: 100%;

    @media (--viewportSmall) {
        /* width: 310px; */
        display: grid;
        align-items: center;
        margin: 0px auto;
        padding: 20px;
    }
}

.planCardContent {
    max-width: 280px;
    width: 100%;
    margin: 0 auto;
}
.planCardTitle {
    font-family: 'Galano Grotesque';
    font-weight: 700;
    font-size: 30px;
    line-height: 41px;
    text-align: center;
    color: #000000;
    margin-bottom: 10px;
}

.planCardDesc {
    max-width: 284px;
    width: 100%;
    font-family: 'Galano Grotesque';
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #000000;
    min-height: 78px;
}
.planCardContent > p {
    padding: 0px 20px;

    @media (--viewportSmall) {
        padding: 0px;
    }
}

.planCardContent > :nth-child(4) {
    margin: 0px;
    align-self: flex-start;
}

.planCardContent > img {
    margin: auto;
    height: 50px;
}

.planCardContent > :nth-child(2) {
    font-size: 18px;
    /* margin-top: 30px; */
}

.planPromises {
    margin-bottom: 24px;
    margin-top: 24px;
}

.planPromise {
    display: flex;
    align-items: center;
}

.planPromise:not(:last-child) {
    margin-bottom: 34px;
}

.planPromise p {
    font-family: 'Galano Grotesque';
    margin: 0;
}

.planPromise > svg {
    fill: #fff;
    margin-right: 15px;

    @media (--viewportSmall) {
        margin-right: 27px;
    }
}

.planMetric {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #000000;

    @media (--viewportSmall) {
        /* width: 310px; */
        font-size: 18px;
    }
}
.planMetricDisp {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.planMetricDesc {
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #000000;
    max-width: 200px;
    text-align: left;
}

.price {
    font-family: 'Galano Grotesque';
    font-weight: 700;
    font-size: 25px;
    line-height: 34px;
    text-align: center;
    color: #000000;
    margin-bottom: 43px;
}

.whiteButton {
    color: #ff7a00;
    border: solid 2px #ff7a00;
    height: 41px;
    border-radius: 5px;
    /* width: 60%; */
    width: 100%;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
}

.orangeButton {
    border: none;
    background: #ff7a00;
    color: white;
    height: 41px;
    border-radius: 5px;
    /* width: 60%; */
    width: 100%;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
}

.planCardContent > button {
    padding: 12px 30px;
    max-width: 268px;
    width: 100%;
}

.planCardDesc {
    min-height: 110px;
}

.checkIcon {
    margin-right: 20px;
}

.planCardsMobile > .alice-carousel {
    outline: 1px solid orangered !important;
}

@custom-media --viewportSmall (min-width: 550px);
@custom-media --viewportMedium (min-width: 768px);
@custom-media --viewportLarge (min-width: 1024px);
@custom-media --viewportLargeWithPaddings (min-width: 1128px);
@custom-media --viewportXLarge (min-width: 1921px);
