.regionMobile {
    display: initial;
    width: 100%;

    @media (--viewportMedium) {
        display: none;
        margin-top: 15%;
    }
}

.regionWeb {
    display: none;
    @media (--viewportMedium) {
        display: initial;
        width: 40%;
        position: relative;
        /* top: -62px; */
        left: 30%;
    }
    @media (--viewportLarge) {
        top: -86px;
    }
}

.nextButtonContainer {
    width: 90%;
    margin: auto;
    text-align: right;

    @media (--viewportMedium) {
        width: 50%;
    }

    @media (--viewportLarge) {
        width: 47%;
    }
}

.regionSelect {
    font-weight: bold;
    border: 1px solid black;
    width: 90%;
    margin: auto;
    padding: 15px;
    font-size: smaller;
    border-radius: 5px;

    @media (--viewportMedium) {
        width: 30%;
        margin: 0;
        font-size: 16px;
    }

    @media (--viewportXLarge) {
        font-size: 20px;
        height: 100px;
        border-radius: 12px;
    }
}
.regionSelection {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-flow: column;
    @media (--viewportMedium) {
        display: flex;
        width: 100%;
        justify-content: center;
        text-align: center;
        flex-flow: row;
    }

    @media (--viewportLarge) {
        align-items: center;
    }
}

.calculatorContainer {
    background-color: white;
}

.calculatorContainer select {
    appearance: none;
    background: url(../../assets/calculator/arrow-down.svg) 96%/7% no-repeat;
    @media (--viewportXLarge) {
        background: url(/static/media/arrow-down.b2da49f2.svg) 96%/5% no-repeat;
    }
}

.Wrapper {
    position: relative;
    background-color: white;
    max-height: 800px;
    overflow: hidden;
    z-index: 0;

    /* @media (--viewportXLarge) {
    height: 900px;
  }

  @media (--viewportMedium) {
    height: 700px;
  } */
}

.regionWrapper {
    background-color: white;
    position: relative;
}

.regionHeader {
    margin: 20px auto;

    @media (--viewportMedium) {
        width: 20%;
        font-size: 35px;
        margin: 0;
        padding-top: 10px;
    }
    @media (--viewportLarge) {
        font-size: 38px;
        padding-top: 0px;
        width: 17%;
    }

    @media (--viewportXLarge) {
        width: 12%;
        font-size: 60px;
    }
}

.regionFact {
    background: #edffe1;
    width: 90%;
    margin: 30px auto;
    padding: 30px 20px 30px 50px;
    font-size: 12px;
    line-height: normal;
    color: #01644c;
    font-weight: bold;
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 10px;

    @media (--viewportMedium) {
        width: 50%;
        padding: 30px 25px 30px 60px;
        /* font-size: 12px; */
    }
    @media (--viewportXLarge) {
        width: 45%;
        font-size: 20px;
    }
}
.exclaim {
    width: 8%;
    position: absolute;
    left: 10px;
    top: 40%;

    @media (--viewportMedium) {
        width: 7%;
    }
    @media (--viewportLarge) {
        width: 6%;
    }
    @media (--viewportLargeWithPaddings) {
        width: 4%;
    }
    @media (--viewportXLarge) {
        width: 3%;
    }
}
.residenceSelection {
    width: 80%;
    margin: auto;
    text-align: center;
    margin-top: 125px;

    @media (--viewportMedium) {
        text-align: center;
        position: relative;
        top: -135px;
        left: 10px;
        margin-top: 130px;
    }
    @media (--viewportLarge) {
        top: -175px;
        margin-top: 40px;
    }

    @media (--viewportLargeWithPaddings) {
        top: -270px;
    }

    @media (--viewportXLarge) {
        top: -300px;
    }
}

.residenceHeader {
    font-size: 28px;

    @media (--viewportMedium) {
        width: 31%;
        margin-top: 140px;
        margin-left: 0;
        font-size: 24px;
        padding-top: 10px;
        margin-bottom: 30px;
    }
    @media (--viewportLarge) {
        width: 57%;
        margin-top: -90px;
        font-size: 35px;
        margin-bottom: 30px;
        margin-left: -100px;
    }
    @media (--viewportLargeWithPaddings) {
        font-size: 44px;
    }
    @media (--viewportXLarge) {
        margin-left: -220px;
        font-size: 50px;
    }

    @media (--viewportMedium) {
        margin-top: 90px;
    }
}

.residenceFact {
    background: #edffe1;
    width: 90%;
    margin: 30px auto 70px auto;
    padding: 30px 20px 30px 50px;
    font-size: 12px;
    line-height: normal;
    color: #01644c;
    font-weight: bold;
    position: relative;
    z-index: 5;
    border-radius: 10px;

    @media (--viewportMedium) {
        width: 33%;
        padding: 24px 25px 17px 40px;
        top: -140px;
        left: -27%;
        font-size: 10px;
        margin-left: 260px;
    }
    @media (--viewportLarge) {
        left: -190px;
        font-size: 12px;
    }
    @media (--viewportLargeWithPaddings) {
        left: -119px;
        top: -235px;

        /* font-size: 15px; */
    }
    @media (--viewportXLarge) {
        top: -270px;
        left: -150px;
        font-size: 20px;
    }
}

.wasteHeader {
    @media (--viewportMedium) {
        width: 52%;
        /* margin-top: -25px; */
        margin-left: 0;
        font-size: 28px;
        padding-top: 10px;
        margin-bottom: 30px;
    }
    @media (--viewportLarge) {
        width: 57%;
        /* margin-top: -90px; */
        font-size: 45px;
        margin-bottom: 30px;
        margin-left: -100px;
    }
}

.residenceSelect {
    font-weight: bold;
    border: 1px solid black;
    width: 90%;
    margin: auto;
    padding: 10px;
    padding-right: 50px;
    font-size: small;
    border-radius: 5px;
    @media (--viewportMedium) {
        width: 35%;
        margin: 0;
    }
    @media (--viewportLarge) {
        padding: 15px;
        padding-right: 50px;
    }

    @media (--viewportXLarge) {
        padding: 15px;
        padding-right: 50px;

        font-size: 20px;
    }
}

.residenceMobile {
    display: initial;
    width: 100%;
    margin-top: 15%;

    @media (--viewportMedium) {
        width: 50%;
        position: absolute;
        left: 45%;
        top: inherit;
    }

    @media (--viewportLarge) {
        margin-top: 7%;
    }

    @media (--viewportXLarge) {
        /* width: 25%; */
        width: 35%;
        position: absolute;
        left: 50%;
        top: -245px;
    }

    @media (--viewportLargeWithPaddings) {
        /* width: 25%; */
        top: -100px;
        width: 45%;
        margin-top: 11%;
    }
}
.Sectors {
    display: none;
    @media (--viewportMedium) {
        display: initial;
        width: 25%;
        position: relative;
        top: -117px;
        left: 75%;
        z-index: 1;
    }
    @media (--viewportLarge) {
        top: -152px;
    }
    @media (--viewportXLarge) {
        top: -203px;
        left: 75%;
    }
}

.People {
    display: initial;
    width: 100%;
    margin-top: 24%;
    margin-bottom: 30px;

    @media (--viewportMedium) {
        width: 50%;
        position: absolute;
        left: 44%;
        top: -88px;
        z-index: 6;
        margin-bottom: 0;
    }
    @media (--viewportLarge) {
        width: 45%;
        position: absolute;
        left: 45%;
        top: -150px;
        /* -250 */
        z-index: 6;
    }

    @media (--viewportLargeWithPaddings) {
        width: 50%;
        left: 42%;
        top: -210px;
    }

    @media (--viewportXLarge) {
        width: 37%;
        position: absolute;
        left: 47%;
        top: -240px;
    }
    @media (min-width: 2560px) {
        top: -570px;
    }
}

.Appliances {
    display: initial;
    width: 87%;
    margin-top: 15%;
    position: relative;
    left: 5%;

    @media (--viewportMedium) {
        width: 60%;
        position: absolute;
        left: 44%;
        top: -8px;
    }
    @media (--viewportLarge) {
        width: 61%;
        position: absolute;
        left: 40%;
        top: -100px;
        /* -100 */
        z-index: -1;
    }

    @media (--viewportLargeWithPaddings) {
        width: 57%;
        position: absolute;
        left: 39%;
        top: -144px;
    }

    @media (--viewportXLarge) {
        width: 49%;
        left: 40%;
        top: -340px;
    }
}

.Towers {
    display: initial;
    width: 70%;
    margin-top: 15%;
    position: relative;
    left: 14%;

    @media (--viewportMedium) {
        width: 45%;
        position: absolute;
        left: 50%;
        top: 38px;
    }

    @media (--viewportLarge) {
        width: 40%;
        position: absolute;
        left: 45%;
        top: -105px;
        z-index: -1;
    }

    @media (--viewportLargeWithPaddings) {
        width: 45%;
    }

    @media (--viewportXLarge) {
        width: 30%;
        left: 53%;
        top: -355px;
    }
}

.Transport {
    width: 100%;
    margin-top: 15%;

    @media (--viewportMedium) {
        width: 50%;
        position: absolute;
        left: 42%;
        top: -20px;
        /* z-index: 1; */
    }
    @media (--viewportLarge) {
        width: 55%;
        position: absolute;
        left: 45%;
        top: -65px;
        /* -190 */
        z-index: 1;
    }
    @media (--viewportLargeWithPaddings) {
        width: 50%;
        top: -111px;
        left: 45%;
    }

    @media (--viewportXLarge) {
        width: 42%;
        left: 42%;
        top: -200px;
    }
}

.FoodCart {
    width: 100%;
    margin-top: 15%;
    display: none;

    @media (--viewportMedium) {
        display: initial;
        width: 45%;
        position: absolute;
        left: 48%;
        top: -24px;
        z-index: 1;
    }
    @media (--viewportLarge) {
        display: initial;
        width: 37%;
        position: absolute;
        left: 54%;
        top: 28px;
        z-index: 9;
    }
    @media (--viewportXLarge) {
        width: 30%;
        left: 53%;
        top: -250px;
    }
}

.FoodCartMobile {
    width: 100%;
    margin-top: 55px;
    @media (--viewportMedium) {
        display: none;
    }
}

.Blop {
    width: 100%;
    margin-top: 15%;
    display: none;

    @media (--viewportMedium) {
        display: initial;
        width: 60%;
        position: absolute;
        left: 40%;
        top: -146px;
        z-index: 1;
    }

    @media (--viewportLarge) {
        display: initial;
        width: 54%;
        position: absolute;
        left: 45%;
        top: -18%;
        z-index: 7;
    }

    @media (--viewportLargeWithPaddings) {
        top: -23%;
    }

    @media (--viewportXLarge) {
        width: 34%;
        left: 50%;
        top: -430px;
    }
}

.SourceCart {
    display: none;
    @media (--viewportMedium) {
        display: initial;
        width: 38%;
        position: absolute;
        left: 50%;
        top: 55px;
        z-index: 1;
    }
    @media (--viewportLarge) {
        display: initial;
        width: 30%;
        position: absolute;
        left: 57%;
        top: 163px;
        z-index: 9;
    }

    @media (--viewportLargeWithPaddings) {
        top: 185px;
    }

    @media (--viewportXLarge) {
        width: 25%;
        left: 55%;
        top: 80px;
    }
}

.residenceActive .energyActive .transportActive .foodActive .wasteActive {
    color: blue;
}

.resultHeader {
    width: 75%;
    margin: 0 auto 35px;
    padding-top: 90px;
    margin-left: 70px;
    text-align: center;
    font-size: 45px;

    @media (--viewportMedium) {
        margin-left: 45px;
        text-align: left;
        width: 79%;
        margin-top: 35px;
        font-size: 40px;
    }
    @media (--viewportLarge) {
        font-size: 50px;
        margin-left: 45px;
    }

    @media (--viewportLargeWithPaddings) {
        font-size: 50px;
        margin-bottom: 20px;
        margin-left: 70px;
    }

    @media (--viewportXLarge) {
        margin-left: 45px;
        font-size: 60px;
    }
}

.footprintResult {
    border: 1px solid #cacaca;
    width: 80%;
    margin: 0 auto 20px;
    border-radius: 12px;
    padding: 20px;
    text-align: center;
    font-weight: bold;
    flex-flow: column;
    font-size: 18px;
    display: flex;
    @media (--viewportMedium) {
        width: 35%;
        font-size: smaller;
        height: 100%;
        padding: 15px;
        margin: 0 auto;
    }
    @media (--viewportLarge) {
        width: 35%;
        font-size: larger;
        padding: 40px;
    }
    @media (--viewportXLarge) {
        width: 30%;
    }
}
.active {
    border-bottom: 2px solid;
    border-bottom-color: #fd9c28;
    color: #a3a3a3;
}

.highlightSmall {
    color: #fd9c28;
}
.highlightNumber {
    font-size: x-large;
    font-weight: 600;
    margin: 15px auto;
    color: #fd9c28;

    @media (--viewportMedium) {
        font-size: 20px;
    }
    @media (--viewportLarge) {
        font-size: 22px;
    }

    @media (--viewportLargeWithPaddings) {
        font-size: 30px;
    }

    @media (--viewportXLarge) {
        font-size: 40px;
    }
}

.footprintResult p {
    width: 70%;
    margin: auto;
    @media (--viewportMedium) {
        font-size: 10px;
        width: 100%;
        margin-top: 20px;
    }
    @media (--viewportLarge) {
        font-size: 19px;
    }
}

.resultWrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    /* flex-flow: column; */

    @media (--viewportMedium) {
        /* flex-flow: row; */
        padding-left: 45px;
        padding-right: 45px;
        padding-top: 75px;
    }
    @media (--viewportLarge) {
        padding-left: 70px;
        padding-right: 70px;
        padding-bottom: 20px;
    }
}

.resultCardWrapper {
    width: 90%;
    margin: auto;
    padding-top: 20px;

    @media (--viewportLarge) {
        width: 100%;
        display: flex;
        background: #f3f3f3;
        min-height: 300px;
        justify-content: space-around;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 10px;
        flex-wrap: wrap;
        border-radius: 5px;
    }
}

.resultCardHeader {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 1em;

    @media (--viewportLarge) {
        font-size: 30px;
    }

    @media (--viewportLargeWithPaddings) {
        margin: 1.5rem;
        font-size: 40px;
    }

    @media (--viewportXLarge) {
        font-size: 50px;
    }
}

/* .Result {
  padding-top: 20px;
} */

.Result > div > :nth-child(2) > nav {
    background-color: #031506;
}

.Result > div > :nth-child(2) > nav > a {
    padding: 0px 21px;
    display: flex;
    align-items: center;
}

.resultCardItems {
    flex: 1;
    text-align: center;
    min-width: 200px;
    border: 1px solid #b6b6b6;
    background-color: #f3f3f3;
    margin: auto;
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 0.25rem;

    @media (--viewportMedium) {
        border: none;
    }

    @media (--viewportSmall) {
        margin-bottom: 0px;
    }
}

.resultCardItemBorderImage {
    min-width: 150px;
    text-align: center;
    flex: 1;
    border: 1px solid #b6b6b6;
    background-color: #f3f3f3;
    margin: auto;
    border-radius: 0.25rem;
    margin-bottom: 1rem;
    margin-top: 0px;
    padding: 1rem;

    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;

    @media (--viewportSmall) {
        border-right: 1px solid #b6b6b6;
        border-left: 1px solid #b6b6b6;
        border-top: none;
        border-bottom: none;
        border-radius: 0;
        padding-bottom: 0px;

        grid-template-columns: auto;
        grid-template-rows: auto auto;
    }

    @media (--viewportMedium) {
        margin-bottom: 0px;
        /* grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content:center; */
    }

    @media (--viewportLargeWithPaddings) {
        margin-bottom: 0px;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        align-items: center;
        justify-content: center;
    }

    /* @media (--viewportMedium) {
    border-left: 1px solid #b6b6b6;
    border-right: 1px solid #b6b6b6;
} */

    /* @media (--viewportLarge) {
       
    } */
}

.resultCardItemBorderImage > img {
    width: 120px;

    @media (--viewportSmall) {
        width: 165px;
        grid-column: 1 / span 2;
        height: 150px;
    }

    @media (--viewportLargeWith) {
        width: 165px;
    }

    @media (--viewportLargeWithPaddings) {
        height: 200px;
        grid-column: 2;
        grid-row: 1 / span 2;
    }

    @media (--viewportXLarge) {
        width: 300px;
    }
}

.resultCardItemBorder {
    /* padding-left: 5px;
  padding-right: 5px */

    min-width: 150px;
    text-align: center;
    flex: 1;
    border: 1px solid #b6b6b6;
    background-color: #f3f3f3;
    margin: auto;
    border-radius: 0.25rem;
    margin-bottom: 1rem;
    margin-top: 0px;
    padding: 1rem;

    /* display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center; */

    @media (--viewportSmall) {
        border-right: 1px solid #b6b6b6;
        border-left: 1px solid #b6b6b6;
        border-top: none;
        border-bottom: none;
        border-radius: 0;
        padding-bottom: 0px;

        /* grid-template-columns: auto ;
    grid-template-rows: auto auto; */
    }

    @media (--viewportMedium) {
        margin-bottom: 0px;
        /* grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content:center; */
    }

    @media (--viewportLarge) {
        margin-bottom: 0px;
        /* grid-template-columns: auto;
    align-items: center;
    justify-content:center; */
    }

    /* @media (--viewportMedium) {
    border-left: 1px solid #b6b6b6;
    border-right: 1px solid #b6b6b6;
} */

    /* @media (--viewportLarge) {
       
    } */
}

.resultCardItemBorder > img {
    width: 120px;

    @media (--viewportMedium) {
        width: 165px;
        /* grid-column: 1/ span 2;
    height: 150px; */
    }

    @media (--viewportLargeWithPaddings) {
        height: 200px;
    }

    @media (--viewportXLarge) {
        width: 300px;
    }
}

.resultEarthText {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    flex: 1;

    @media (--viewportMedium) {
        font-size: 21px;
    }

    @media (--viewportLargeWithPaddings) {
        font-size: 24px;
    }

    @media (--viewportXLarge) {
        font-size: 35px;
        line-height: normal;
    }
}

.actionText {
    width: auto;
}

.resultCardActionItem {
    flex: 1;
    text-align: left;
    padding-left: 20px;
    min-width: 200px;
    border: 1px solid #b6b6b6;
    background-color: #f3f3f3;
    margin: auto;
    border-radius: 0.25rem;
    padding: 1rem;

    @media (--viewportSmall) {
        border: none;
    }
}

.resultCardActionItem > h2 {
    @media (--viewportXLarge) {
        font-size: 40px;
    }
}

.earthWrapper {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    margin: auto;
    width: 100%;

    @media (--viewportMedium) {
        flex-flow: row;
        width: 100%;
    }

    @media (--viewportLarge) {
        flex-flow: row;
        width: 100%;
    }

    @media (--viewportLargeWithPaddings) {
        flex-flow: row;
        width: 100%;
    }
}

.ecologicalChartWrapper {
    display: flex;
    justify-content: center;
    font-size: 12px;
    margin-top: 22px;
}

.ecologicalChartDonut {
    width: 180px;

    @media (--viewportSmall) {
        width: 230px;
    }

    @media (--viewportMedium) {
        width: 95px;
        padding-right: 15px;
        height: none;
    }

    @media (--viewportLarge) {
        width: 230px;
    }

    @media (--viewportXLarge) {
        margin: auto;
        margin-right: inherit;
    }
}

.ecologicalChartLabels {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    font-weight: bold;
    min-width: max-content;

    @media (--viewportXLarge) {
        margin: auto;
        margin-left: inherit;
    }
}

.carbonResult {
    /* margin-top: 20px; */
}

.resultGlobalWarmingText > .orange {
    color: #fd9c29;
}

.resultGlobalWarmingText {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    flex: 1;

    @media (--viewportSmall) {
        grid-column: 1 / span 2;
    }

    @media (--viewportMedium) {
        font-size: 21px;
        /* grid-column: 1; */
        grid-row: 2;
    }

    @media (--viewportLargeWithPaddings) {
        grid-row: 1 / span 2;
        grid-column: 1;
    }

    @media (--viewportXLarge) {
        font-size: 35px;
        line-height: normal;
    }
}

.resultCo2CloudImageContainer {
    position: relative;
}

.resultCo2CloudImage {
    width: 180px;
    padding-left: 10px;
    margin-top: 30px;

    @media (--viewportMedium) {
        width: 195px;
    }

    @media (--viewportLargeWithPaddings) {
        width: 212px;
    }

    @media (--viewportXLarge) {
        width: 300px;
    }
}

.resultCo2CloudImageText {
    position: absolute;
    z-index: 1;
    bottom: 5px;
    left: 0;
    right: 0;
    margin: 0px auto;
    font-size: 11px;
    font-weight: bold;

    @media (--viewportMedium) {
        font-size: 13px;
        bottom: 16px;
    }

    @media (--viewportLarge) {
        font-size: 14px;
        left: 6px;
    }

    @media (--viewportLargeWithPaddings) {
        font-size: 15px;
        left: 6px;
    }

    @media (--viewportXLarge) {
        font-size: 22px;
    }
}

.resultCardCFItem {
    flex: 1;
    text-align: center;
    min-width: 200px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: center;
    border: 1px solid #b6b6b6;
    background-color: #f3f3f3;
    margin: auto;
    margin-bottom: 1rem;
    border-radius: 0.25rem;
    padding: 1rem;

    @media (--viewportSmall) {
        border: none;
        margin-bottom: 0px;
    }

    @media (--viewportMedium) {
        margin: auto;
        margin-bottom: 0px;
    }
}

.resultCardGrayText {
    padding-top: 20px;
    color: #5a5a5a;
    margin: auto;
    width: 70%;
    font-family: Galano Grotesque;
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 26px;
    text-align: center;
}

.resultShareCard {
    flex-direction: column;
    background-color: #009341;
    color: white;
    /* margin: 20px 0; */
    border-radius: 5px 5px 0px 0px;
    padding: 10px 40px;
    display: flex;
    align-items: center;

    @media (--viewportMedium) {
        flex-direction: row;
    }
}

.resultShareCardBottom {
    background-color: #009341;
    color: white;
    border-radius: 0px 0px 5px 5px;
    padding: 45px 10px;
    align-items: center;
}

.resultShareCardBottom > :first-child {
    width: 100%;
    margin: auto;
    padding: 20px;
    display: flex;

    @media (--viewportMedium) {
        width: 100%;
    }

    @media (--viewportLarge) {
        width: 70%;
    }

    @media (--viewportLargeWithPaddings) {
        width: 70%;
    }
}

.resultShareCardSubHeading {
    padding: 0px 10px;
    width: 84%;
    text-align: center;

    @media (--viewportLargeWithPaddings) {
        padding: 0px 68px 0px 82px;
        width: fit-content;
    }
}

.resultShareCardSubHeading strong {
    font-size: 20px;
}

.resultShareCard h2 {
    font-size: 30px;
}

.resultShareCardBottom > div > input {
    background-color: white;
    color: black;
    padding: 12px;
    border-radius: 5px 0px 0px 5px;
    border: none;
}

.iconCircles {
    width: 100%;
    margin-bottom: 15px;
    margin-top: 7px;
    @media (--viewportLarge) {
        margin-bottom: 32px;
        margin-top: 22px;
    }
}

.earthResult {
    width: 80%;
    margin: 0 auto 20px;
    border-radius: 12px;
    text-align: center;
    background-color: #f3f3f3;
    font-size: 30px;
    padding: 35px;
    @media (--viewportMedium) {
        width: 60%;
        margin: 0px 20px;
        padding: 20px;
        height: 100%;
    }
    @media (--viewportLarge) {
        width: 50%;
    }
    @media (--viewportXLarge) {
        width: 45%;
    }
}

.socialWrapper {
    margin-bottom: 60px;
    height: 100%;
    @media (--viewportXLarge) {
        width: 25%;
    }
}

.sectorsResult {
    width: 80%;
    position: relative;
    top: -35px;
    left: 29%;
    z-index: 6;
    @media (min-width: 320px) {
        left: 29%;
    }
    @media (min-width: 375px) {
        left: 25%;
    }
    @media (--viewportMedium) {
        left: 20%;
        top: -19px;
    }
    @media (--viewportLarge) {
        left: 29%;
        top: -20px;
        width: 50%;
    }
    @media (--viewportXLarge) {
        width: 45%;
    }
}

.resultEarth {
    width: 60px;
    position: absolute;
    top: 394px;
}
.earthText {
    margin-top: -50px;
    font-size: xx-large;
    line-height: 1.6;

    @media (--viewportMedium) {
        margin-top: -48px;
        font-size: initial;
    }
    @media (--viewportLarge) {
        font-size: larger;
    }
}

.joinOthers {
    padding: 34px;
    box-sizing: border-box;
    border: 1px solid;
    border-radius: 12px;
    width: 80%;
    margin: auto;
    color: white;
    background-color: #002822;
    text-align: left;
    @media (--viewportMedium) {
        width: 80%;
        padding: 15px;
        margin: 0;
    }
    @media (--viewportLarge) {
        margin-bottom: 25px;
        padding: 30px;
    }
    @media (--viewportXLarge) {
        padding: 75px;
    }
}

.socialHeader {
    @media (--viewportMedium) {
        line-height: 1;
        margin-top: 0;
        margin-bottom: 0;
    }
    @media (--viewportLarge) {
        margin-top: 25px;
        margin-bottom: 25px;
    }
}

.socialText {
    font-size: 16px;
    line-height: 1.3;
    margin-top: 0px;
    margin-bottom: 16px;
    @media (--viewportLarge) {
        margin-bottom: 25px;
    }
    @media (--viewportXLarge) {
        font-size: 22px;
    }
}

.resultButton {
    border: none;
    background: #ff7a00;
    color: white;
    height: 60px;
    border-radius: 5px;
    width: 80%;
    margin: 20px auto;
    font-size: initial;
    font-weight: bold;
    cursor: pointer;

    @media (--viewportMedium) {
        height: 35px;
        font-size: small;
        margin: 0 auto;
    }
    @media (--viewportLarge) {
        width: 80%;
        font-size: large;
        padding: 5px;
        height: 50px;
    }
}

.inviteFriendButton {
    border: none;
    background: #ff7a00;
    color: white;
    height: 60px;
    border-radius: 5px;
    width: 90%;
    margin: 20px auto;
    font-size: initial;
    font-weight: bold;
    cursor: pointer;

    @media (--viewportMedium) {
        width: 46%;
        font-size: large;
        margin: 0 auto;
    }

    @media (--viewportLargeWithPaddings) {
        width: 33%;
        font-size: large;
        margin: 0 auto;
        margin-right: 30px;
    }
}

.inviteFriend {
    box-sizing: border-box;
    padding: 34px;
    border: 1px solid;
    border-radius: 12px;
    width: 80%;
    margin: 25px auto 0;
    color: white;
    background-color: #009341;
    text-align: left;
    @media (--viewportMedium) {
        width: 80%;
        padding: 15px;
        margin: 0;
    }
    @media (--viewportLarge) {
        padding: 30px;
    }
    @media (--viewportXLarge) {
        padding: 75px;
    }
}

.ListWrapper {
    list-style: none;
    padding: 0;
    display: flex;
    position: relative;
    top: 50px;
    left: 5%;
    z-index: 1;
    width: max-content;
    @media (min-width: 365px) {
        left: 8%;
    }
    @media (min-width: 425px) {
        left: 13%;
    }
    @media (--viewportLarge) {
        left: 33%;
        top: 10px;
    }
}

.ListWrapper div {
    font-size: 12px;
    line-height: 24px;
    font-weight: bold;
    border-right: 1px black;
    @media (min-width: 325px) {
        font-size: 15px;
    }
    @media (--viewportMedium) {
        font-size: 14px;
    }
    @media (--viewportLarge) {
        font-size: initial;
    }
}

.ecologicalFootballFields {
    padding-top: 1rem;
    color: #5a5a5a;
    margin: auto;
    font-size: 20px;

    @media (--viewportXLarge) {
        margin-top: 60px;
        font-size: 30px;
    }
}

.ecologicalFootballFieldsValues {
    margin: 0px;
    padding-top: 20px;
    font-size: 16px;

    @media (--viewportLargeWithPaddings) {
        font-size: 18px;
    }
}

.navigationButtonsContainer {
    display: flex;
    justify-content: space-between;
}

.copyLinkButton {
    border: none;
    background: #ff7a00;
    color: white;
    height: 49.61px;
    border-radius: 0px 5px 5px 0px;
    width: 50%;
    font-size: initial;
    font-weight: bold;
    cursor: pointer;

    @media (--viewportMedium) {
        width: 46%;
        font-size: large;
        margin: 0 auto;
    }
}

.fbButton {
    padding: 5px 10px;
    display: flex;
    align-items: center;
    background-color: #3b5a98;
    cursor: pointer;
    color: white;
    border-radius: 5px;
    font-size: 15px;
    border: none;
    font-weight: bold;
    width: 100%;
    @media (--viewportMedium) {
        padding: 10px 25px;
    }
}

.shareButtons > a {
    margin: auto;
    width: 75%;

    @media (--viewportSmall) {
        width: 270px;
        margin: 0px;
    }
}

.shareButtons > :nth-child(1) {
    @media (--viewportSmall) {
        margin-right: 20px;
    }
}

.twButton {
    padding: 5px 10px;
    display: flex;
    align-items: center;
    background-color: #1da1f2;
    font-size: 15px;
    cursor: pointer;
    border: none;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    width: 100%;

    @media (--viewportMedium) {
        padding: 10px 25px;
    }
}

.socialIcons {
    margin-right: 35px;
    font-size: 27px;
}

.shareButtons {
    display: grid;
    grid-row-gap: 1rem;

    @media (--viewportMedium) {
        display: flex;
        justify-content: center;
    }

    @media (--viewportLargeWithPaddings) {
        display: flex;
        justify-content: center;
    }
}

/** new code **/
.Container {
    /* margin-top: 40px; */
    background: white;
    display: grid;
    grid-template-rows: auto auto;
    padding-bottom: 15px;
    margin-top: 60px;

    @media (--viewportSmall) {
        /* margin-top: 100px; */
        grid-template-rows: auto;
        margin-top: 0px;
    }

    @media (--viewportLargeWithPaddings) {
        grid-template-columns: 1fr 1fr;
    }
}

.leftContainer {
    display: grid;
    grid-template-rows: repeat(4, auto);
    width: 80%;
    margin: auto;

    @media (--viewportSmall) {
        width: 60%;
        margin-top: 100px;
    }
}

.leftContainer > h2 {
    text-align: center;
}

.rightContainer {
    grid-row: 1;
    text-align: center;

    @media (--viewportSmall) {
        display: flex;
        justify-content: center;
        grid-column: 2;
    }
}

.rightContainer > img {
    width: 90%;
    margin-top: 0px;

    @media (--viewportSmall) {
        /* width: 90%; */
        margin-top: 55px;
        max-height: 450px;
    }
}

.question {
    font-size: 44px;
    line-height: 38px;
}

.select {
    width: 100%;
    font-weight: bold;
    border: 1px solid black;
    margin: auto;
    padding: 10px;
    height: 60px;
    padding-right: 50px;
    font-size: small;
    border-radius: 5px;
}

.navigationButtons {
    padding: 0.4rem 0.8rem;
    border: 1px solid black;
    border-radius: 5px;
    cursor: pointer;
    font-size: 13px;
    margin-top: 15px;
    width: 100px;
}

.disclaimer {
    background: #edffe1;
    width: 100%;
    margin: 15px auto;
    padding: 30px 20px 30px 50px;
    font-size: 12px;
    line-height: normal;
    color: #01644c;
    font-weight: bold;
    position: relative;
    z-index: 5;
    border-radius: 10px;

    @media (--viewportSmall) {
        width: 120%;
    }
}

.sectorsImage {
    position: absolute;
    /* width: 22%; */
    width: 250px;
    top: 0;
    right: 0;
    z-index: 10;
    display: none;

    @media (--viewportLarge) {
        display: flex;
    }
}

.navbar {
    display: grid;
    grid-template-rows: auto auto;
    padding-top: 50px;

    @media (--viewportSmall) {
        grid-template-columns: 1fr 3fr;
        grid-template-rows: auto;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    @media (--viewportMedium) {
        grid-template-columns: 0.82fr 3fr;
        grid-template-rows: none;
    }
}

.logoContainer {
    padding: 30px 0px 30px 20px;

    @media (--viewportSmall) {
        padding-top: 18px;
        padding-bottom: 10px;
    }
}

@custom-media --viewportSmall (min-width: 550px);
@custom-media --viewportMedium (min-width: 768px);
@custom-media --viewportLarge (min-width: 1024px);
@custom-media --viewportLargeWithPaddings (min-width: 1128px);
@custom-media --viewportXLarge (min-width: 1921px);
