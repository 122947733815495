.setupAccountMainHeading {
    font-family: 'Galano Grotesque';
    font-size: 38px;
    font-weight: 700;
    line-height: 52px;
    letter-spacing: 0em;
    text-align: center;
    margin: 0;
    padding: 0;
}

.actionButtons {
    display: flex;
    justify-content: space-between;
    margin-top: 35px;
}

@media screen and (max-width: 1385px) {
    .actionButtons {
        margin-top: 10px;
        height: 115px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
}
.signupButtonContainer {
    display: flex;
    justify-content: center;
}
