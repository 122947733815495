@import '../../marketplace.css';

.root {
}

.pageRoot {
    display: flex;
    padding-bottom: 90px;
    @media (--viewportLarge) {
        padding-bottom: 0;
    }
}

.loadingText {
    margin: 24px;
}

.errorText {
    color: var(--failColor);
    margin: 24px;
}

.joinButton {
    @apply --marketplaceButtonStylesPrimary;
    white-space: nowrap;
    padding: 8px 10px;

    @media (--viewportMedium) {
        left: auto;
        right: 24px;
        padding: 12px 45px;
    }
}

.threeToTwoWrapper {
    /* Layout */
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: -1;
}

.threeToTwoWrapper:before {
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
    display: block;
    position: absolute;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
    position: relative; /* allow positioning own listing action bar */
    background-color: var(--matterColorNegative); /* Loading BG color */
    height: 100%;
    /* Image carousel can be opened from the image, therefore we should show a pointer */
    cursor: pointer;

    @media (--viewportMedium) {
        padding-bottom: 0; /* No fixed aspect on desktop layouts */
    }
}

.actionBar {
    position: fixed;
    display: flex;
    justify-content: space-between;
    bottom: 0;
    left: 0;
    width: 100%;
    color: var(--matterColorNegative);
    background-color: var(--matterColor);
    z-index: 1; /* bring on top of mobile image */

    /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
    cursor: initial;

    border-radius: var(--borderRadius);
}

.ownListingText {
    @apply --marketplaceH4FontStyles;
    margin: 14px 12px 11px 24px;

    @media (--viewportMedium) {
        margin: 25px 12px 22px 24px;
    }
}

.ownListingTextPendingApproval {
    color: var(--attentionColor);
}

.closedListingText {
    @apply --marketplaceH4FontStyles;
    margin: 14px 12px 11px 24px;
    text-align: center;
    width: 100%;

    @media (--viewportMedium) {
        margin: 25px 12px 22px 24px;
    }
}

.editListingLink {
    @apply --marketplaceH4FontStyles;
    flex-shrink: 0;
    margin: 0;
    padding: 14px 24px 11px 12px;
    color: var(--matterColorNegative);

    transition: var(--transitionStyleButton);

    &:hover {
        color: var(--matterColorLight);
        text-decoration: none;
    }

    @media (--viewportMedium) {
        margin: 0;
        padding: 25px 24px 22px 12px;
    }
}

.editIcon {
    margin: -6px 7px 0 0;
}

.rootForImage {
    /* Layout - image will take space defined by aspect ratio wrapper */
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    transition: var(--transitionStyleButton);

    @media (--viewportMedium) {
        position: static;
        top: auto;
        bottom: auto;
        left: auto;
        right: auto;
        height: 100%;
        object-fit: cover;

        &:hover {
            transform: scale(1.005);
            box-shadow: var(--boxShadowListingCard);
        }
    }
}

.viewPhotos {
    @apply --marketplaceTinyFontStyles;
    font-weight: var(--fontWeightMedium);

    /* Position and dimensions */
    position: absolute;
    bottom: 19px;
    right: 24px;
    margin: 0;
    padding: 6px 13px 8px 13px;

    /* Colors */
    background-color: var(--matterColorLight);

    /* Borders */
    border: none;
    border-radius: var(--borderRadius);

    cursor: pointer;
    transition: var(--transitionStyleButton);

    &:hover {
        background-color: var(--marketplaceColor);
        color: var(--matterColorLight);
    }

    @media (--viewportMedium) {
        margin: 0;
    }
}

.carouselModalScrollLayer {
    width: 100vw;
    height: 100vh;
    background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
    width: 100%;
    height: 100%;
}

.contentContainer {
    padding: 0 36px 0 36px;
    display: flex;
    flex-direction: column-reverse;
    @media (--viewportMedium) {
        max-width: 1056px;
        margin: 0 auto;
        padding: 0 24px;
        display: grid;
        grid-template-columns: 1.5fr 2fr;
        grid-column-gap: 40px;
        position: relative;
        margin-top: 42px;
    }

    @media (--viewportLarge) {
        max-width: 1128px;
        padding: 0 36px;
        margin: 0 auto 117px;
        margin-top: 42px;
    }

    @media (--viewportXLarge) {
        max-width: 1056px;
        padding: 0;
        margin-top: 42px;
    }
}

.storyLayout .contentContainer {
    margin-top: 0;
    display: block;
}

.mainContent {
    flex-basis: 100%;
    margin-bottom: 23px;
    @media (--viewportMedium) {
        margin-bottom: 51px;
        flex-shrink: 0;
    }

    @media (--viewportLarge) {
        margin-bottom: 0px;
        /* contentContainer's full width - (bookingColumn + two paddings + margin + border) */
        flex-basis: calc(100% - 493px);
        flex-shrink: 0;
        flex-grow: 1;
    }
}

.bookingPanel {
    @media (--viewportLarge) {
        display: block;
        margin-top: 95px;
        margin-left: 60px;
        border-left-style: solid;
        border-left-width: 1px;
        border-left-color: var(--matterColorNegative);

        padding-left: 60px;

        /* The calendar needs 312px */
        flex-basis: 312px;
        flex-shrink: 0;
    }
}

.sectionAvatar {
    /* Position (over the listing image)*/
    margin-left: 24px;
    margin-top: -31px;

    /* Rendering context to the same lavel as listing image */
    position: relative;

    /* Flex would give too much width by default. */
    width: 60px;

    @media (--viewportMedium) {
        position: absolute;
        left: 60px;
        top: -49px;
        margin-left: 0;
        margin-top: 0;
    }
}

.avatarMobile {
    display: flex;

    @media (--viewportMedium) {
        display: none; /* Hide the medium avatar from the bigger screens */
    }
}

.avatarDesktop {
    display: none; /* Hide the large avatar from the smaller screens */

    @media (--viewportMedium) {
        display: flex;
    }
}

.sectionHeading {
    @media (--viewportMedium) {
        display: flex;
    }
}
.desktopPriceContainer {
    display: none;

    @media (--viewportLarge) {
        /* Layout */
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-shrink: 0;
        margin: 0 48px 0 0;
        padding: 0;
    }
}

.desktopPriceValue {
    /* Font */
    @apply --marketplaceH1FontStyles;
    color: var(--marketplaceColor);

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 6px;
    }
}

.desktopPerUnit {
    /* Font */
    @apply --marketplaceH5FontStyles;
    color: var(--matterColor);

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.storyseparator {
    margin-bottom: 10px;
}
.storyfavoritecoutner {
    margin-bottom: 30px;
}
.storyLayout {
    display: grid;
    max-width: 900px;
    grid-template-columns: 1fr 1fr;
    margin: 150px auto 0 auto;
}

.storyLayout :last-child {
    grid-row: 1;
}
.storyLayout .hero {
    border-radius: 8px;
    overflow: hidden;
}

.heading {
    width: 100%;
    align-items: center;
    justify-content: space-between;
    @media (--viewportMedium) {
        display: flex;
        margin-bottom: 0;
        padding: 0;
    }
}

.headingStories {
    text-align: center;
    margin-bottom: 33px;
    color: white;
    width: 100%;
    margin: auto;
    max-width: 900px;
    padding: 0 20px;
    align-items: center;
    justify-content: space-between;
}

.title {
    /* Font */
    @apply --marketplaceH1FontStyles;
    color: white;
    /* Layout */
    width: 100%;
    margin-top: 0;
    margin: 0;
    margin-bottom: 10px;
    @media (--viewportMedium) {
        margin-top: 0;
    }
}

.title.titledark {
    color: black;
}

.author {
    width: 100%;
    @apply --marketplaceH4FontStyles;
    margin-top: 7px;
    margin-bottom: 0;
    color: white;
    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.authorNameLink {
    color: var(--matterColor);
}

.contactWrapper {
    display: none;

    @media (--viewportMedium) {
        display: inline;
    }
}

.separator {
    margin: 0 6px;
}

.contactLink {
    @apply --marketplaceLinkStyles;
    @apply --marketplaceH4FontStyles;
    margin: 0;
}

.sectionDescription {
    margin-bottom: 35px;

    @media (--viewportSmall) {
        margin-bottom: 52px;
    }
}

.descriptionTitle {
    /* Font */
    @apply --marketplaceH3FontStyles;
    color: var(--matterColorAnti);

    margin-top: 0;
    margin-bottom: 13px;
    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 20px;
    }
}

.sectionFeatures {
    padding: 0 24px;
    margin-bottom: 32px;

    @media (--viewportMedium) {
        padding: 0;
        margin-bottom: 52px;
    }
}

.featuresTitle {
    /* Font */
    @apply --marketplaceH3FontStyles;
    color: var(--matterColorAnti);

    margin-top: 0;
    margin-bottom: 16px;
    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 20px;
    }
}

.description {
    margin-top: 0;
    margin-bottom: 0;

    /* Preserve newlines, but collapse other whitespace */
    white-space: pre-line;

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.sectionMap {
    position: relative;
    padding-bottom: 100%;

    @media (--viewportMedium) {
        padding-bottom: 100%;
    }
}

.locationTitle {
    /* Font */
    @apply --marketplaceH3FontStyles;
    color: var(--matterColorAnti);

    margin-top: 0;
    margin-bottom: 20px;
    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 28px;
    }
}

.sectionReviews {
    padding: 0 24px;
    margin-bottom: 5px;

    @media (--viewportMedium) {
        padding: 0;
    }
    @media (--viewportLarge) {
        margin-bottom: 3px;
    }
}

.reviewsHeading {
    @apply --marketplaceH3FontStyles;
    color: var(--matterColorAnti);

    margin: 52px 0 20px 0;

    @media (--viewportMedium) {
        margin: 53px 0 27px 0;
    }
    @media (--viewportLarge) {
        margin: 50px 0 26px 0;
    }
}

.sectionHost {
    position: relative;
    margin-bottom: 5px;

    @media (--viewportMedium) {
        padding: 0;
    }
    @media (--viewportLarge) {
        margin-bottom: 3px;
    }
}

.yourHostHeading {
    @apply --marketplaceH3FontStyles;
    color: var(--matterColorAnti);

    margin: 54px 0 23px 0;

    @media (--viewportMedium) {
        margin: 53px 0 27px 0;
    }
    @media (--viewportLarge) {
        margin: 53px 0 27px 0;
    }
}

.enquiryModalContent {
    flex-grow: 1;
    display: flex;
}

.enquiryForm {
    flex-grow: 1;
    margin: 70px 0 100px 0;

    @media (--viewportMedium) {
        display: flex;
        flex-direction: column;
        min-height: 400px;
        margin: 0;
    }
}

.enquirySubmitButtonWrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 24px;
    background-color: var(--matterColorLight);

    @media (--viewportMedium) {
        position: static;
        padding: 0;
        margin-top: auto;
    }
}

.map {
    /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    /* Static map: dimensions are 640px */
    max-width: 640px;
    max-height: 640px;
    background-color: #eee;
    padding: 0;
    border: 0;
    cursor: pointer;
}

.longWord {
    /* fallback option */
    word-break: break-all;
    /* use break-word if available */
    word-break: break-word;
    hyphens: auto;
}

.sectionImages {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.sectionContentHeading {
    width: 100%;
    margin: 0 auto;
    position: relative;
    padding: 0 36px 30px 36px;
    max-width: 1128px;
}
.sectionContent {
    width: 100%;
    position: relative;
    margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px;
    padding: 0 36px 0 36px;
    margin: 50px auto 50px auto;
    @media (--viewportMedium) {
        margin: 93px auto 93px auto;
        max-width: 100%;
    }

    @media (--viewportLarge) {
        max-width: 1128px;
    }

    @media (--viewportXLarge) {
        max-width: 1056px;
        padding: 0;
    }
}
.hero {
    position: relative;
    display: flex;
    align-items: flex-end;
    height: 250px;
    @media (--viewportSmall) {
        height: 450px;
    }
}

.heroTitle {
    width: 100%;
}

.impact {
    align-items: flex-start;
    padding: 20px 0;
    border-top: 1px solid #ddd;
    border-width: 1px 0;
    @media (--viewportSmall) {
        display: flex;
        margin-top: 32px;
    }
}

.partnerSection {
    padding: 20px 0;
    border-top: 1px solid #ddd;
    border-width: 1px 0;
    @media (--viewportSmall) {
        margin-top: 32px;
    }
}

.impactTitle {
    margin-top: 0;
}

.impactContent {
    margin-top: 5px;
    @media (--viewportSmall) {
        margin-top: 0;
        margin-left: 30px;
    }
}

.impactList {
    margin-bottom: 0;
}

.sideTitle {
    font-size: 20px;
    margin: 0;
}

.sideList {
    margin: 0;
}

.sideListItem {
    font-size: 14px;
}

.logoList {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 5px;
    grid-column-gap: 5px;
    @media (--viewportSmall) {
        grid-template-columns: repeat(4, 1fr);
    }
    @media (--viewportLarge) {
        grid-template-columns: repeat(6, 1fr);
    }
}

.logoListInnerWrapper {
    padding: 5px;
    position: relative;
    border: 1px solid #ccc;
    padding-bottom: 100%;
    width: 100%;
    height: 0;
    border-radius: 2px;
}

.logoListItemPic {
    width: 95%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
}

.especiesList {
    display: grid;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 20px;
    @media (--viewportSmall) {
        grid-template-columns: repeat(3, 1fr);
    }
}

.especiesListPic {
    width: 100%;
    height: 110px;
    object-fit: cover;
    @media (--viewportSmall) {
        height: 140px;
    }
}

.especiesListPicLabel {
    margin: 5px 0 0 0;
    line-height: 1.2;
    font-size: 16px;
    text-align: center;
    text-transform: lowercase;
}

.especiesListPicLabel::first-letter {
    text-transform: uppercase;
}

.firstTitle {
    margin-top: 0;
}

.sideSubTitle {
    font-size: 14px;
    margin: 0 0 15px 0;
}

.mapImage {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 20px;
}

.stickyContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (--viewportMedium) {
        max-width: 1056px;
        margin: 0 auto;
    }

    @media (--viewportLarge) {
        max-width: 1128px;
    }

    @media (--viewportXLarge) {
        max-width: 1056px;
    }
}

.stikyJoinButton {
    @apply --marketplaceButtonStylesPrimary;
    padding: 6px 10px;
    @media (--viewportMedium) {
        padding: 12px 45px;
    }
}
.stickyTitle {
    font-weight: 800;
    font-size: 18px;
    @media (--viewportMedium) {
        font-size: 24px;
    }
}
.stickyCall {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
    padding: 0 10px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.12);
    z-index: 9999;
    animation: appear 0.3s ease-out;
}

@keyframes appear {
    0% {
        transform: translateY(80px);
    }
    100% {
        transform: translateY(0);
    }
}

.linkProfile {
    display: block;
    text-align: center;
    font-size: 16px;
}

.IconPic {
    top: 3px;
    right: 3px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.2);
    position: absolute;
}

.logoSrcContainer {
    padding-bottom: 100%;
    display: flex;
    align-items: center;
    height: 100%;
    border-radius: 5px;
    border: 1px solid #eaeaea;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.08);
    position: relative;
    text-align: center;
}

.picLinksLogo {
    height: 100%;
    display: flex;
    align-items: center;
}

.newLabel {
    position: absolute;
    top: 1px;
    right: 1px;
    width: 0;
    height: 0;
    border-top: 230px solid rgb(221, 107, 23); /* Adjust the height of the triangle */
    border-left: 230px solid transparent; /* Adjust the width of the triangle */
    z-index: 1;
    span {
        position: absolute;
        top: -184px; /* Adjust to center the text */
        right: -6px; /* Adjust to center the text */
        color: white;
        font-weight: bold;
        transform: rotate(45deg);
        font-size: 28px;
        text-align: center;
        letter-spacing: 1px;
    }
}
