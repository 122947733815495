@import '../../marketplace.css';

.sectionContent {
  width: 100%;
  margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px;
  padding: 0 36px 0 36px;
  margin: 50px auto 50px auto;
  @media (--viewportMedium) {
    margin: 93px auto 93px auto;
    max-width: 100%;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}


.paisajes{
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  @media (--viewportMedium) {
    grid-template-columns: 1fr 1fr;
  }
  @media (--viewportLarge) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.title{
  font-size: 36px;
}

.text{
  font-size: 32px;
}

.textwrapper{
  position: absolute;
  justify-content: space-between;
  align-items: center;
  left: 0;
  width: 100%;
  padding: 0 20px;
  bottom: 20px;
  @media (--viewportMedium) {
    display: flex;
  }
}

.pictext{
  color: white;
  line-height: 1.1;
  font-size: 32px;
  font-weight: 800;
  margin: 0;
  margin-bottom: 10px;
  @media (--viewportMedium) {
    margin-bottom: 0
  }
}

.pic{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 2px
}

.picContainer{
  position: relative;
  background: gray;
  height: 280px;
}

.picContainer:before{
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.25) 30%,  rgba(0, 0, 0, 0.60));
}


.linkButton{
  @apply --marketplaceButtonStylesPrimarySmall
}
