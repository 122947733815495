.formControl {
  margin-bottom: 30px;
}

.formControl label {
  font-family: 'Galano Grotesque';
  font-style: normal;
  text-align: left;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #4a5568;
  margin-bottom: 0.375rem;
}

.formControl .pwd-icon {
  position: relative;
  width: 27px;
  height: 18px;
  left: 90%;
  bottom: 30px;
}

.formControl input:visited + .pwd-icon {
  outline: 1px solid green;
}

.formControl input:not([type='checkbox']),
textarea {
  max-width: 545px;
  min-height: 50px;
  width: 100%;
  background: #ffffff;
  border: 3px solid #000000;
  box-sizing: border-box;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  font-family: 'Galano Grotesque';
  padding: 0.75rem 1.125rem;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.24px;
  color: #494b4e;

  transition: all 0.3s ease-in;
}

.formControl input:not([type='checkbox']),
textarea:hover {
  border-color: #000000;
}

.formControl input:not([type='checkbox']),
textarea:focus {
  outline: none;
  border-color: #000000;
}

.formControl .pwd-icon + .error {
  margin-top: -6px !important;
}

.formControl .checkbox-option {
  max-width: 450px;
  width: 100%;
  min-height: 64px;
  display: flex;
  align-items: center;
  padding: 0 0.875rem;
  margin-bottom: 1.75rem;

  background: #ffffff;
  border: 1px solid #d2d8df;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
}

.formControl .checkbox-option input[type='checkbox'] {
  width: 1.75rem;
  height: 1.5rem;
  margin-right: 0.875rem;
}
.formControl .checkbox-option label {
  font-family: 'Galano Grotesque';
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: #494b4e;
}

.error {
  font-size: 16px;
  font-family: 'Galano Grotesque';
  color: #ff0000;
  font-weight: 500;
  text-align: left;
  white-space: pre-wrap;
  margin-top: 4px;
}

.formControl .success {
  color: green;
  white-space: pre-wrap;
}
.formControl select {
  max-width: 450px;
  width: 100%;
  min-height: 44px;
  border: 1px solid #d2d8df;
  box-sizing: border-box;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  font-family: 'Galano Grotesque';
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  padding: 0.75rem 1.125rem;
  letter-spacing: -0.24px;
  color: #494b4e;

  appearance: none;
  position: relative;
  background-position: right 1.125rem top 50%;

  transition: all 0.3s ease-in;
}

.formControl select:focus {
  outline: none;
  border-color: #000000;
}

.formControl select:hover {
  border-color: #000000;
}
