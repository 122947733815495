.accountSetup {
    max-width: 977px;
    width: 100%;
    margin: 30px auto;
    text-align: center;

    padding: 10px;
    @media (--viewportMedium) {
        padding: 0;
    }
}

.accountSetup > h5:nth-child(1) {
    font-family: 'Galano Grotesque';
    font-weight: 700;
    font-size: 25px;
    line-height: 23px;
    color: #000000;
    margin-bottom: 70px;
    box-sizing: border-box;
}
.accountSetup > h5:nth-child(3) {
    font-family: 'Galano Grotesque';
    font-weight: 700;
    font-size: 25px;
    line-height: 23px;
    color: #000000;
    box-sizing: border-box;
}
.loginFormContainer button {
    display: block;
    margin: 30px auto;
    @media screen and (max-width: 600px) {
        width: 60%;
    }
}

.googleButton {
    max-width: 316px;
    width: 100%;
    padding: 15px;
    background-color: white;
    color: #7d7d7d;
    box-shadow: 3px 3px 12px -7px rgb(0 0 0 / 63%);
    cursor: pointer;
    border-radius: 5px !important;
    font-size: 15px !important;
    border: none;
    font-weight: bold !important;
    margin-top: 21px;
    margin-bottom: 34px;
    text-align: left;
}

.googleButton > div {
    margin-right: 24px !important;
}

.loginForm {
    max-width: 600px;
    padding: 0 7.5px;
    width: 100%;
    margin: 0 auto;

    @media (--viewportLarge) {
        width: 60%;
    }
}
.loginFormRow {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (--viewportMedium) {
        flex-direction: row;
        justify-content: space-between;
    }
}

.loginForm > .formControl input:not([type='checkbox']),
textarea {
    max-width: 265px;
}

.spinner {
    animation: spin infinite 2s linear;
    margin-right: 8px;
    /*You can increase or decrease the timer (5s) to 
   increase or decrease the speed of the spinner*/
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.error {
    font-size: 16px;
    font-family: 'Galano Grotesque';
    color: #ff0000;
    font-weight: 500;
    text-align: center;
    margin-top: 8px;
}

.loginButton {
    color: #609333;
    font-size: 17px;
    font-family: 'Galano Grotesque';
}
