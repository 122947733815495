@import '../../marketplace.css';

.sectionContent {
  width: 100%;
  margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px;
  padding: 0 10px 0 10px;
  margin: 50px auto 50px auto;
  @media (--viewportMedium) {
    padding: 0 36px 0 36px;
    margin: 93px auto 93px auto;
    max-width: 100%;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.title{
  font-size: 28px;
  text-align: center;
  @media (--viewportMedium) {
    font-size: 36px;
  }
}


.list{
  padding-left: 30px;
}

.list li{
  position: relative;
  margin-bottom: 40px
}

.list li:before{
  content: "";
  display: block;
  position: absolute;
  top: 10px;
  left: -30px;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: var(--marketplaceColor)
}

.list li li{
  margin-bottom: 15px;
}

.list li li:before{
  width: 10px;
  height: 1px;
  border-radius: 0;
}

.sectionWhite{
  background-color: white;
}
