@import './marketplaceFonts.css';

/* ================ Custom properties aka variables ================ */

:root {
    /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

    /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

    /* Full screen Background image located in root-folder/src/assets */
    --backgroundImage: {
        /* Gradient direction and overlaying the black color on top of the image for better readability */
        background: linear-gradient(1800deg, rgba(0, 0, 0, 0.09) 70%, rgba(0, 0, 0, 0.46)),
            url('../../assets/mainhero.jpg');

        /* Add loading color for the div */
        background-color: var(--matterColor);

        /* Cover the whole screen with the background image */
        background-size: cover;

        /* Align the image within the container */
        background-position: center right;

        @media (--viewportLarge) {
            border-radius: 0;
        }
    }

    /* ================ Colors ================ */

    --marketplaceColor: #6ea44c;
    --marketplaceColorLight: #2dc000;
    --marketplaceColorDark: #1fa860;
    --marketplaceSecondaryColor: #f6a700;
    --marketplaceSecondaryDark: #f68300;

    /* Used with inline CSS SVGs */
    --marketplaceColorEncoded: %23c0392b;

    --successColor: #2ecc71;
    --successColorDark: #497d28;
    --failColor: #ff0000;
    --attentionColor: #ffaa00;
    --bannedColorLight: var(--marketplaceColorLight);
    --bannedColorDark: var(--marketplaceColor);

    --matterColorSubDark: #000000;
    --matterColorDark: #000000;
    --matterColor: #000000;
    --matterColorAnti: #b2b2b2;
    --matterColorNegative: #e7e7e7;
    --matterColorBright: #fcfcfc;
    --matterColorLight: #ffffff;

    --matterColorLightTransparent: rgba(255, 255, 255, 0.65);

    /* ================ Spacing unites ================ */

    /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */
    --spacingUnit: 6px;
    --spacingUnitDesktop: 8px;

    /* Shadows */
    --boxShadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    --boxShadowTop: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
    --boxShadowLight: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    --boxShadowPopup: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
    --boxShadowPopupLight: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
    --boxShadowBreakdown: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
    --boxShadowButton: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    --boxShadowListingCard: 0 0 50px 0 rgba(0, 0, 0, 0.1);
    --boxShadowNotFoundPageSearch: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    --boxShadowSectionLocationHover: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
    --boxShadowBottomForm: 0 -2px 4px 0 rgba(0, 0, 0, 0.05);
    --boxShadowFilterButton: 0 4px 16px 0 rgba(0, 0, 0, 0.2);

    /* ================ Z-index base levels ================ */

    /* topbar is positioned slightly above content */
    --zIndexTopbar: 10;
    /* small popups on UI should use z-indexes above 50 */
    --zIndexPopup: 50;
    /* modals and UI overlays should use z-indexes above 100 */
    --zIndexModal: 100;
    /* generic error message should overlay even modals */
    --zIndexGenericError: 200;

    /* ================ Border radius ================ */

    --borderRadius: 2px;
    --borderRadiusMobileSearch: 3px;

    /* ================ Transition styles ================ */

    --transitionStyle: ease-in 0.2s;
    --transitionStyleButton: ease-in-out 0.1s;

    /* ================ Topbar heights ================ */

    --topbarHeight: 60px;
    --topbarHeightDesktop: 72px;

    /* ================ Modal default padding ================ */

    --modalPadding: 24px 24px 48px 24px;
    --modalPaddingMedium: 55px 60px 55px 60px;

    /* ================ Clearfix solution ================ */

    --clearfix : {
        &:after {
            content: '';
            display: table;
            clear: both;
        }
    }

    /* ================ Links ================ */

    --marketplaceLinkStyles: {
        /* Position and dimensions */
        display: inline;
        margin: 0;
        padding: 0;

        /* Borders */
        border: none;

        /* Colors */
        color: var(--marketplaceColor);

        /* Text size should be inherited */
        text-decoration: none;
        font-family: 'IBM Plex Sans', Helvetica, Arial, sans-serif;

        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }

    /* ================ Buttons ================ */

    --marketplaceButtonFontStyles: {
        font-family: 'sofiapro', Helvetica, Arial, sans-serif;
        font-weight: var(--fontWeightSemiBold);
        font-size: 18px;
        line-height: 1.3;
        letter-spacing: -0.3px;

        @media (--viewportMedium) {
            /* TODO: Make sure button text aligns with the baseline */
        }
    }
    --marketplaceButtonStyles {
        @apply --marketplaceButtonFontStyles;

        /* Dimensions */
        display: inline-block;
        width: auto;
        margin: 0;

        /* Padding is only for <a> elements where button styles are applied,
    buttons elements should have zero padding */
        padding: 12px 45px;

        /* Borders */
        border: none;
        border-radius: 99999px;

        /* Colors */
        background-color: var(--marketplaceColor);
        color: var(--matterColorLight);

        /* Text */
        text-align: center;
        text-decoration: none;

        /* Effects */
        transition: all var(--transitionStyleButton);
        cursor: pointer;

        &:hover,
        &:focus {
            outline: none;
            background-color: var(--marketplaceColorDark);
            text-decoration: none;
            box-shadow: var(--boxShadowButton);
        }
        &:disabled {
            background-color: var(--matterColorNegative);
            color: var(--matterColorLight);
            cursor: not-allowed;
            box-shadow: none;
        }
    }
    --marketplaceButtonStylesPrimary {
        @apply --marketplaceButtonStyles;

        background-color: var(--marketplaceColor);
        color: var(--matterColorLight);
        border-radius: 9999px;
        &:hover,
        &:focus {
            background-color: var(--successColorDark);
        }
        &:disabled {
            background-color: var(--matterColorNegative);
            color: var(--matterColorLight);
        }
    }
    --marketplaceButtonStylesPrimarySmall {
        @apply --marketplaceButtonStyles;

        background-image: linear-gradient(
            90deg,
            var(--marketplaceColorLight),
            var(--marketplaceColorDark)
        );
        color: white;

        border-radius: 9999px;
        border-width: 0;
        border-style: solid;
        border-color: var(--matterColorNegative);
        padding: 2px 15px;
        &:hover,
        &:focus {
            background-color: var(--matterColorLight);
            border-color: var(--matterColorAnti);
        }
        &:disabled {
            background-color: var(--matterColorNegative);
            color: var(--matterColorLight);
            border-color: var(--matterColorNegative);
        }
    }

    --marketplaceButtonStylesSecondarySmall {
        @apply --marketplaceButtonStyles;

        background-image: linear-gradient(
            90deg,
            var(--marketplaceSecondaryColor),
            var(--marketplaceSecondaryDark)
        );
        color: white;

        border-radius: 9999px;
        border-width: 0;
        border-style: solid;
        border-color: var(--matterColorNegative);
        padding: 2px 15px;
        font-size: 16px;
        &:hover,
        &:focus {
            background-color: var(--matterColorLight);
            border-color: var(--matterColorAnti);
        }
        &:disabled {
            background-color: var(--matterColorNegative);
            color: var(--matterColorLight);
            border-color: var(--matterColorNegative);
        }
    }

    --marketplaceButtonStylesSecondary {
        @apply --marketplaceButtonStyles;

        background-image: linear-gradient(
            90deg,
            var(--marketplaceSecondaryColor),
            var(--marketplaceSecondaryDark)
        );
        color: white;
        padding: 12px 45px;

        /* Padding is only for <a> elements where button styles are applied,
   buttons elements should have zero padding */

        border-width: 0;
        border-style: solid;
        border-color: var(--matterColorNegative);

        &:hover,
        &:focus {
            background-color: var(--matterColorLight);
            border-color: var(--matterColorAnti);
        }
        &:disabled {
            background-color: var(--matterColorNegative);
            color: var(--matterColorLight);
            border-color: var(--matterColorNegative);
        }
    }

    --marketplaceButtonStylesSecondarySmall {
        @apply --marketplaceButtonStyles;

        background-image: linear-gradient(
            90deg,
            var(--marketplaceSecondaryColor),
            var(--marketplaceSecondaryDark)
        );
        color: white;
        padding: 2px 15px;

        /* Padding is only for <a> elements where button styles are applied,
   buttons elements should have zero padding */

        border-width: 0;
        border-style: solid;
        border-color: var(--matterColorNegative);

        &:hover,
        &:focus {
            background-color: var(--matterColorLight);
            border-color: var(--matterColorAnti);
        }
        &:disabled {
            background-color: var(--matterColorNegative);
            color: var(--matterColorLight);
            border-color: var(--matterColorNegative);
        }
    }

    --marketplaceButtonStylesHidden {
        @apply --marketplaceButtonStyles;
        background: transparent;
        color: #999;
        padding: 12px 45px;
        border: 2px solid #ccc;
        border-radius: 9999px;
        font-size: 18px;
        font-weight: 400;
        /* Padding is only for <a> elements where button styles are applied,
   buttons elements should have zero padding */

        border-color: var(--matterColorNegative);

        &:hover {
            background: transparent;
        }
        &:focus {
            border-color: var(--matterColorAnti);
        }
        &:disabled {
            color: var(--matterColorLight);
            border-color: var(--matterColorNegative);
        }
    }

    --marketplaceButtonStylesHiddenSmall {
        @apply --marketplaceButtonStyles;
        background: transparent;
        color: #999;
        padding: 2px 15px;
        border: 2px solid #ccc;
        border-radius: 9999px;
        font-size: 18px;
        font-weight: 400;
        /* Padding is only for <a> elements where button styles are applied,
   buttons elements should have zero padding */

        border-color: var(--matterColorNegative);

        &:hover {
            background: transparent;
        }
        &:focus {
            border-color: var(--matterColorAnti);
        }
        &:disabled {
            color: var(--matterColorLight);
            border-color: var(--matterColorNegative);
        }
    }

    /* ================ Inputs ================ */

    --marketplaceInputStyles: {
        /* Dimensions */
        display: block;
        width: 100%;
        margin: 0;
        padding: 5px 0 5px 0;

        /* Borders */
        border: none;
        border-bottom-width: 2px;
        border-bottom-style: solid;
        border-bottom-color: var(--marketplaceColor);
        border-radius: 0;

        &::placeholder {
            color: var(--matterColorAnti);
        }

        /* Effects */

        transition: border-bottom-color var(--transitionStyle);

        &:hover,
        &:focus {
            border-bottom-color: var(--matterColor);
            outline: none;
        }

        @media (--viewportMedium) {
            padding: 4px 0 10px 0;
        }
    }

    --marketplaceSelectStyles: {
        /* Dimensions */
        display: block;
        width: 100%;
        margin: 0;
        padding: 5px 0 5px 0;

        /* Unset user agent styles */
        appearance: none;

        /* Borders */
        border: none;
        border-bottom-width: 2px;
        border-bottom-style: solid;
        border-bottom-color: var(--marketplaceColor);
        border-radius: 0;

        /* Background */
        background-image: url('data:image/svg+xml;utf8,<svg width="14" height="9" viewBox="0 0 14 9" xmlns="http://www.w3.org/2000/svg"><path d="M6.53 7.472c.26.26.68.26.94 0l5.335-5.333c.26-.263.26-.684 0-.944-.26-.26-.683-.26-.943 0L7 6.056l-4.862-4.86c-.26-.26-.683-.26-.943 0-.26.26-.26.68 0 .943L6.53 7.47z" stroke="%234A4A4A" fill="%234A4A4A" fill-rule="evenodd"/></svg>');
        background-size: 16px 16px;
        background-position: center right;

        /* Effects */

        cursor: pointer;
        transition: border-bottom-color var(--transitionStyle);

        &:hover,
        &:focus {
            border-bottom-color: var(--matterColor);
            outline: none;
        }

        @media (--viewportMedium) {
            padding: 4px 0 10px 0;
        }
    }

    /* ================ Modals ================ */

    --marketplaceModalRootStyles {
        /* Page is using flex: AuthenticationPage's .root takes all available space */
        flex-grow: 1;

        /* AuthenticationPage's root uses flexbox */
        display: flex;

        @media (--viewportMedium) {
            justify-content: center;
            align-items: flex-start;
        }
    }

    --marketplaceModalBaseStyles {
        flex-grow: 1;

        /* Create context so the close button can be positioned within it */
        position: relative;

        /* Display and dimensions */
        display: flex;
        flex-direction: column;
        padding: var(--modalPadding);

        background-color: var(--matterColorLight);
        border-radius: var(--borderRadius);
        border-bottom: none;

        @media (--viewportMedium) {
            flex-basis: 480px;
            flex-grow: 0;
            /* min-height: 568px; */
            padding: var(--modalPaddingMedium);
            margin-top: 20.5vh;
            margin-bottom: 12.5vh;
        }
    }

    --marketplaceModalInMobileBaseStyles {
        flex-grow: 1;

        /* Create context so the close button can be positioned within it */
        position: relative;

        /* Display and dimensions */
        display: flex;
        flex-direction: column;
        height: 100vh;
        padding: 0;

        background-color: var(--matterColorLight);
        border-radius: var(--borderRadius);

        @media (--viewportMedium) {
            flex-basis: 480px;
            flex-grow: 0;
            height: 100%;
            padding: 0;
            background-color: transparent;
            border-radius: 0;
        }
    }

    /* Modal title */

    --marketplaceModalTitleStyles {
        font-weight: var(--fontWeightSemiBold);
        font-size: 30px;
        line-height: 1.35px;
        letter-spacing: -0.5px;
        margin: 0;
        font-weight: var(--fontWeightBold);

        @media (--viewportMedium) {
            font-weight: var(--fontWeightSemiBold);
            line-height: 40px;
            letter-spacing: -0.9px;
            margin: 0;
        }
    }

    /* Modal message */

    --marketplaceModalParagraphStyles {
        margin: 18px 0 0 0;

        @media (--viewportMedium) {
            margin: 24px 0 0 0;
        }
    }

    /* Bottom wrapper for the modal */

    --marketplaceModalBottomWrapper {
        text-align: center;
        margin-top: 60px;
        align-self: stretch;

        @media (--viewportMedium) {
            margin-top: 96px;
        }
    }

    /* Adjust the bottomWrapper's margin to match modals without the helper text */
    --marketplaceModalBottomWrapperText {
        margin-top: -24px;
        padding-bottom: 8px;

        @media (--viewportMedium) {
            padding-bottom: 0;
        }
    }

    --marketplaceModalHelperText {
        @apply --marketplaceH5FontStyles;
        color: var(--matterColorAnti);
        margin: 0;

        @media (--viewportMedium) {
            margin: 0;
        }
    }

    --marketplaceModalHelperLink {
        @apply --marketplaceH5FontStyles;
        color: var(--matterColor);
        margin: 0;

        @media (--viewportMedium) {
            margin: 0;
        }
    }

    /* Modal Close icon */

    --marketplaceModalCloseStyles {
        /* Position inside modal */
        position: fixed;
        top: 0;
        right: 0;

        /* Some content (like map) is positioning itself on top of close button without z-index handling */
        z-index: calc(var(--zIndexModal) + 1);

        /* Safari didn't know how to baseline-align svg icon and text */
        display: flex;
        align-items: flex-start;
        width: auto;

        /* Sizing (touch area) */
        padding: 24px;
        margin: 0;
        border: 0;

        @media (--viewportMedium) {
            padding: 27px 30px;
            position: absolute;
        }

        /* Colors */
        background-color: transparent;
        color: var(--matterColor);

        cursor: pointer;

        &:enabled:hover {
            background-color: transparent;
            color: var(--matterColorDark);
            box-shadow: none;
            text-decoration: none;
        }
        &:enabled:active {
            background-color: transparent;
            color: var(--matterColorDark);
        }
        &:disabled {
            background-color: transparent;
        }
    }

    --marketplaceModalIconStyles {
        height: 48px;
        margin-bottom: 12px;

        @media (--viewportMedium) {
            height: 64px;
            margin-bottom: 23px;
        }
    }

    --marketplaceModalCloseText {
        /* Font */
        @apply --marketplaceH6FontStyles;
        margin: -2.5px 0 0 0;

        @media (--viewportMedium) {
            margin-top: -1px;
        }
    }

    --marketplaceModalCloseIcon {
        display: inline-block;
        margin-left: 8px;
        padding: 2px 0 4px;
        box-sizing: content-box;
    }

    --marketplaceModalErrorStyles {
        @apply --marketplaceH4FontStyles;
        margin-top: 24px;
        color: var(--failColor);
    }

    --marketplaceModalPasswordMargins {
        /* Leave space between the input and the button below when the
    viewport height is small */
        margin-top: 24px;

        @media (--viewportMedium) {
            margin-top: 32px;
        }
    }
}

/* ================ Custom media queries ================ */

@custom-media --viewportSmall (min-width: 550px);
@custom-media --viewportMedium (min-width: 768px);
@custom-media --viewportLarge (min-width: 1024px);
@custom-media --viewportLargeWithPaddings (min-width: 1128px);
@custom-media --viewportXLarge (min-width: 1921px);
