@import '../../marketplace.css';

.root {
    /* Fixed on top of everything */
    position: fixed;
    bottom: 0;
    z-index: 1000;

    /* Dimensions */
    width: 100vw;
    padding: 5px 0;

    /* Layout */
    display: flex;
    justify-content: center;
    align-items: center;

    /* Colors */
    background-color: #6ea44c;
    color: var(--matterColorNegative);

    /* Cover Topbar on desktop */
    @media (--viewportMedium) {
        padding: 8px 0;
    }
}

.message {
    @apply --marketplaceH5FontStyles;
    margin-left: 24px;
}

.cookieLink {
    color: var(--matterColorLight);
    border-bottom: 1px solid var(--matterColorNegative);

    &:hover {
        text-decoration: none;
    }
}

.continueBtn {
    /* Font */

    font-weight: var(--fontWeightSemiBold);

    /* Dimensions */
    padding: 3px 12px 3px 12px;
    margin: 0 24px 0 6px;

    background-color: #dd6b17;
    border: solid 1px var(--matterColorNegative);
    border-radius: 4px;
    cursor: pointer;
    color: white;
    transition: var(--transitionStyleButton);

    &:hover {
        background-color: #c05303;
        color: white;
        transition: var(--transitionStyleButton);
    }

    @media (--viewportMedium) {
        margin: 0 24px;
    }
}
