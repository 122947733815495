.upButton{
    width: 60px;
    height: 60px;
    border-radius: 100%;
    position: fixed;
    bottom: 20px;
    left: 20px;
    border: 1px solid #d0d0d0;
    background-color: white;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
    z-index: 99999;
    cursor: pointer
}