.component {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: black;
    opacity: 0.4;
    z-index: 10;
}
.blogDetailPage {
    padding: 0 35px;
    overflow-x: hidden;
}
@media screen and (max-width: 850px) {
    .blogDetailPage {
        padding: 0 15px;
    }
}
.titleSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 35px;
    padding-left: 20px;
}
@media screen and (max-width: 850px) {
    .titleSection {
        flex-direction: column;
        align-items: flex-start;
        padding-right: 0;
        width: 100%;
        margin-bottom: 10px;
    }
    .titleSection .shareButton {
        margin-top: 10px;
    }
    .titleSection .metaSection {
        width: 100%;
    }
    .titleSection .metaSection .meta {
        width: 100%;
        justify-content: space-between;
        flex-direction: column;
        padding-right: 25px;
    }
}

.blogTitle {
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-weight: 700;
    margin: 0;
    font-size: 55px;
    line-height: 118%;
    padding-right: 25px;
    padding-bottom: 10px;
}

@media screen and (max-width: 1100px) {
    .blogTitle {
        font-size: 42px;
    }
}
@media screen and (max-width: 850px) {
    .blogTitle {
        font-size: 32px;
    }
}
.metaSection {
    display: flex;
    flex-direction: column;
}
.meta {
    display: flex;
}
.metaSection .readTime {
    font-family: 'Galano Grotesque';
    font-weight: 700;
    font-size: 30px;
    margin: 0;
    margin-right: 25px;
}

@media screen and (max-width: 850px) {
    .metaSection .readTime {
        font-size: 18px;
    }
}
.metaSection .tags {
    display: flex;
    align-items: center;
}

.metaSection .tags span {
    padding-left: 10px;
    margin: 0;
}
@media screen and (max-width: 850px) {
    .titleSection .metaSection .meta .tags span {
        padding-left: 0px;
        padding-right: 10px;
    }
}
.featuredImage {
    padding: 15px 0;
    width: 100%;
}

.featuredImage img {
    height: 100%;
    width: 100%;
}
.blogContent {
    display: flex;
    padding: 20px 20px;
}
@media screen and (max-width: 850px) {
    .blogContent {
        flex-direction: column;
        align-items: center;
        padding: 0px 0px;
    }
}

.authorSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px;
}

.authorSection img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
}
@media screen and (max-width: 850px) {
    .authorSection {
        flex-direction: row;
        justify-content: center;
    }
    .authorSection img {
        width: 60px;
        height: 60px;
    }
}
.authorSection p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 21.39px;
    line-height: 26px;
    padding-top: 10px;
    text-align: center;
}

@media screen and (max-width: 850px) {
    .authorSection p {
        font-size: 16px;
        line-height: 18px;
        text-align: start;
        padding-left: 15px;
    }
}

.blogContentHtml {
    font-family: 'Galano Grotesque';
    margin: 15px;
}
.blogContentHtml {
    text-align: center;
}

.blogContentHtml h1 {
    text-align: left;
    margin: 0;
    margin: 15px 0;
}
.blogContentHtml h2 {
    text-align: left;
    margin: 0;
    margin: 15px 0;
}
.blogContentHtml h3 {
    text-align: left;
    margin: 0;
    margin: 15px 0;
}

.blogContentHtml h4 {
    text-align: left;
    margin: 0;
    margin: 12px 0;
}
.blogContentHtml h5 {
    text-align: left;
    margin: 0;
    margin: 10px 0;
}
.blogContentHtml h6 {
    text-align: left;
    margin: 0;
    margin: 10px 0;
}

.blogContentHtml p {
    text-align: left;
    margin: 0;
    padding: 0;
    margin: 12px 0;
}
.blogContentHtml img {
    width: 100%;
    height: auto !important;
    margin: 10px 0;
}

.relatedBlogs {
    border-radius: 14px;
    position: relative;
    margin: 25px 0;
}

.relatedBlogs > .alice-carousel {
    outline: 1px solid orangered !important;
}
.carouselNextButton,
.carouselBackButton {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 0;
    padding: 0 10px;
    transition: all 0.2s ease-in;
    outline: 0;
    cursor: pointer;
    opacity: 0.7;
    /* background: #f1f1f1; */
}

.carouselNextButton:hover,
.carouselBackButton:hover {
    background-color: rgba(255, 255, 255, 0.2);
    transition: all 0.2s ease-in;
    opacity: 1;
}

.carouselNextButton:active,
.carouselBackButton:active {
    background-color: rgba(255, 255, 255, 0.5);
    opacity: 1;
}

.carouselNextButton {
    right: 0px;
}

.carouselBackButton {
    left: 0px;
}
.carouselChevron {
    font-size: 40px;
    color: gray;
}
