@import '../../../../marketplace.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 5px;
  font-size: 18px;
  font-weight: 700;
  background-color: white;

  @media screen and (max-width: 860px) {
    max-width:290px;
    background-color:black;
    padding:0;
  }

  @media (--viewportLarge) {
    /* padding: 112px 0 82px 36px; */
    font-size: 18px;
    flex-direction: column;
    justify-content: flex-start;
    border: none;
    box-shadow: none;
    /* background-color: #FBFBFB; */
  }

}

.tabs {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  min-height: 48px;
  padding-top: 2px;
  font-size: 18px;
  

  @media (--viewportLarge) {
    min-height: auto;
    flex-direction: column;
    margin-top: 10px;
    font-size: 18px;
    padding-top: 0;
  }
}

.tab {
  display: flex;
  align-items: flex-start;
  height: 100%;
  margin: 0px 5% 0px 0px;
  font-size: 18px;
}
