@import '../../marketplace.css';

.root h2{
    /* Adjust heading margins to work with the reduced body font size  */
    margin: 23px 0 19px 0;

    @media (--viewportMedium) {
      margin: 24px 0 24px 0;
    }
} 
.root .lastUpdated {
  @apply --marketplaceBodyFontStyles;
  margin-top: 0;
  margin-bottom: 55px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 54px;
  }
}


.root ul{
  padding-left: 30px!important;
}

.root ul li{
  position: relative;
  margin-bottom: 40px
}

.root ul li:before{
  content: "";
  display: block;
  position: absolute;
  top: 10px;
  left: -30px;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: var(--marketplaceColor)
}

.root ul li li{
  margin-bottom: 15px;
}

.root ul li li:before{
  width: 10px;
  height: 1px;
  border-radius: 0;
}
