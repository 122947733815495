.payment h5 {
    font-family: 'Galano Grotesque';
    font-weight: 700;
    font-size: 25px;
    line-height: 23px;
    text-align: center;
    color: #000000;
    margin-bottom: 40px;
}

.paymentFormContainer {
    padding: 15px;
    margin-bottom: 40px;
}

.paymentFormFieldContainer {
    max-width: 575px;
    width: 100%;
    margin: 0 auto;
}

.paymentForm button {
    display: block;
    margin: 0 auto;
}

.polenTypeWidth {
    max-width: 605px;
}
.paymentForm .cardAddInfo {
    display: flex;
}

.checkBox {
    display: flex;
    align-items: flex-start;
    margin-bottom: 33px;
}

.checkBox > input {
    width: 13px;
    margin-right: 17px;
    margin-top: 4px;
}

.checkBox > label {
    width: 95%;
    font-family: 'Galano Grotesque';
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    padding: 0;
}

.checkBox > label > a {
    font-family: 'Galano Grotesque';
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    padding: 0;
    text-decoration: underline;
    margin: 0 5px;
}

.checkBoxError > .error > p {
    text-align: left;
    margin-top: -16px;
}

.stripeCard {
    max-width: 504px;
    width: 100%;
    min-height: 123px;
    background: #000000;
    border-radius: 8px;
    padding: 30px 26px;
    margin-bottom: 18px;
}

.cardNumber > div > input {
    border: 0.5px solid #e5e5e5;
    border-radius: 5px;
    padding: 0.5rem;
    margin: 10px 0px 25px 0px;
}

.stripeCard > h4 {
    font-family: 'Galano Grotesque';
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #ffffff;
    margin-bottom: 10px;
}

.stripeCard h5 {
    font-family: 'Galano Grotesque';
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #ffffff;
    margin: 0;
}

.stripeCard .stripeCardImgCont {
    display: flex;
    align-items: center;
    margin-left: -10px;
}

.stripeCardImgCont > img {
    margin-right: 7px;
}

.memberShipDetails > h4 {
    font-family: 'Galano Grotesque';
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #4a5568;
    margin-top: 39px;
    margin-bottom: 52px;
}
.memberShipDetails > p {
    font-family: 'Galano Grotesque';
    font-weight: 600;
    font-size: 18px;
    line-height: 29px;
    color: #000000;
    margin-bottom: 40px;
}

.memberShipDetails a {
    color: #bad79f;
}

/* Stripe styling */

.formElementWrapper {
    margin-bottom: 30px;
}

.formElementWrapper label {
    font-family: 'Galano Grotesque';
    font-style: normal;
    text-align: left;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #4a5568;
    margin-bottom: 0.375rem;
}

.stripeElemWrapper,
.cardinputName {
    max-width: 545px;
    min-height: 50px;
    width: 100%;
    background: #ffffff;
    border: 3px solid #000000;
    box-sizing: border-box;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    font-family: 'Galano Grotesque';
    padding: 12px 22px;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.24px;
    color: #494b4e;

    transition: all 0.3s ease-in;
}

.stripeElemWrapper > input {
    padding: 0;
    border: 0;
    line-height: 16px;
    font-family: 'Galano Grotesque';
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #2d3748;
}

.spinner {
    animation: spin infinite 2s linear;
    margin-right: 8px;
    /*You can increase or decrease the timer (5s) to 
   increase or decrease the speed of the spinner*/
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.error > p {
    font-size: 16px;
    font-family: 'Galano Grotesque';
    color: #ff0000;
    font-weight: 500;
    text-align: center;
    white-space: pre-wrap;
    margin-top: 8px;
}
