@import '../../marketplace.css';

.sectionContent {
    width: 100%;
    margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px;
    padding: 0 10px 0 10px;
    @media (--viewportMedium) {
        padding: 0 36px 0 36px;
        margin: 93px auto 93px auto;
        max-width: 100%;
    }

    @media (--viewportLarge) {
        max-width: 1128px;
    }

    @media (--viewportXLarge) {
        max-width: 1056px;
        padding: 0;
    }
}

.title {
    font-size: 28px;
    text-align: center;
    @media (--viewportMedium) {
        font-size: 36px;
    }
}

.list {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 50px;
    grid-row-gap: 50px;
    align-items: stretch;
    margin-top: 78px;
    text-align: center;
    @media (--viewportMedium) {
        grid-template-columns: 1fr 1fr;
    }
    @media (--viewportLarge) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

.listItem {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.iconWrapperList {
    text-align: center;
}

.listItemText {
    margin: 10px 0 0 0;
    @media (--viewportMedium) {
        margin-top: 28px;
    }
}

.IconPic {
    top: 3px;
    right: 3px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.2);
    position: absolute;
}

.businessSection {
    margin-top: 0;
}

.listIcon {
    max-width: 60px;
}

.toBuy {
    @apply --marketplaceButtonStyles;
    @apply --SectionHero_animation;
    animation-delay: 0.8s;
}

.logosPicWrapper {
    display: grid;
    max-width: 700px;
    margin: 40px auto 0 auto;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    align-items: center;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    margin-top: 60px;
    text-align: center;
    @media (--viewportMedium) {
        grid-template-columns: repeat(4, 1fr);
    }
    @media (--viewportLarge) {
        grid-template-columns: repeat(5, 1fr);
    }
}
.logoSrcContainer {
    padding: 10px;
    display: flex;
    align-items: center;
    height: 100%;
    border-radius: 5px;
    border: 1px solid #eaeaea;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.08);
    position: relative;
    text-align: center;
}

.logosPicWrapper img {
    width: 100%;
}

.logoNoLinks {
    box-shadow: none;
}

.picLinksLogo {
    height: 100%;
    display: flex;
    align-items: center;
}

.testimony {
    background-color: #f5f5f5;
}

.testimonyList {
    display: grid;
    text-align: center;
    grid-template-columns: 1fr;
    grid-column-gap: 22px;
    grid-row-gap: 40px;
    @media (--viewportMedium) {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.testimonyDescription {
    margin-top: 24px;
    text-align: center;
    font-size: 18px;
    font-style: italic;
    font-weight: 200;
}

.testimonyPeople {
    font-size: 16px;
}

.testimonyPic {
    width: 130px;
    height: 130px;
    object-fit: cover;
    border-radius: 100%;
    object-position: top;
}
.testimonyPeopleName {
    color: #666666;
}

.plans {
    position: relative;
}

.plans:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 50%;
    background-image: linear-gradient(90deg, #2dc000, #1fa860);
    z-index: -1;
}

.helpCopy {
    font-size: 22px;
    text-align: center;
    margin: 0;
    margin-bottom: 30px;
}

.formContent {
    @media (--viewportMedium) {
        align-items: center;
        display: flex;
    }
}

.textForm {
    margin: 0;
    color: white;
    font-weight: 800;
}

.buttonForm {
    white-space: nowrap;
    flex-shrink: 0;
    margin-top: 30px;
    margin-bottom: 20px;
    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 50px;
    }
}

.video {
    width: 420px;
    height: 240px;
}

.modalVideo {
    padding: 2px;
    background: white;
    top: 0;
    bottom: 0;
    position: relative;
    margin: auto;
}

.modalVideo > button {
    left: 0;
}

.actionsButtons {
    display: flex;
}

.videoButton_text {
    margin-right: 8px;
}
.videoButton {
    cursor: pointer;
    display: flex;
    padding: 5px 10px;
    align-items: center;
    border: 0;
    text-align: left;
    justify-content: flex-start;
    color: white;
}

.modalButtonContainer {
    display: flex;
    padding-top: 50px;
    justify-content: center;
}

@media (--viewportMedium) {
    .video {
        width: 680px;
        height: 380px;
    }
}
