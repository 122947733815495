.timelineContainer {
  display: flex;
  margin: auto;
  position: relative;

  @media (--viewportSmall) {
    width: 72%;
    margin-left: 0px;
  }
}

.timelineItem {
  background-color: #c4c4c4;
  border-radius: 50%;
  width: 25px;
  font-size: 0px;
  margin: 0px 10px;

  @media (--viewportSmall) {
    height: 25px;
    width: 25px;
    border: 0.5px solid #5f6368;
    border-radius: 50%;
    background-color: white;
    text-align: center;
    font-size: 14px;
    color: #5f6368;
    margin: 0px;
  }

  @media (--viewportMedium) {
    padding: 0px;
    font-size: 12px;
  }

  @media (--viewportLarge) {
    height: 30px;
    width: 30px;
    padding: 2px;
    font-size: 15px;
  }

  @media (--viewportLargeWithPaddings) {
    height: 35px;
    width: 35px;
    border: 0.5px solid #5f6368;
    border-radius: 50%;
    background-color: white;
    text-align: center;
    padding: 4px;
    font-size: 14px;
  }
}

.timelineItemLabel {
  position: absolute;
  top: 40px;
  font-size: 14px;
  width: 120px;
  left: -42.5px;
  text-align: center;
  display: none;
  color: black;

  @media (--viewportSmall) {
    position: absolute;
    display: block;
    top: 40px;
    font-size: 14px;
    width: 120px;
    left: -42.5px;
    text-align: center;
    color: black;
  }
}

.timelineItemLabelCurrent {
  position: absolute;
  top: 50px;
  font-size: 14px;
  text-align: center;
  color: black;
  left: 0px;
  right: 0px;
  display: inline;

  @media (--viewportSmall) {
    display: none;
  }
}

.timelineItemCurrent {
  background-color: #34a853;
  border-radius: 50%;
  width: 25px;
  font-size: 0px;
  margin: 0px 10px;

  @media (--viewportSmall) {
    height: 25px;
    width: 25px;
    border: 0.5px solid #34a853;
    border-radius: 50%;
    background-color: white;
    text-align: center;
    font-size: 12px;
    margin: 0px;
  }

  @media (--viewportMedium) {
    height: 25px;
    width: 25px;
    padding: 0px;
    font-size: 12px;
  }

  @media (--viewportLarge) {
    height: 30px;
    width: 30px;
    padding: 2px;
    font-size: 15px;
  }

  @media (--viewportLargeWithPaddings) {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    background-color: white;
    text-align: center;
    padding: 5px;
  }
}

.timelineItemCurrent > span {
  margin: auto;
  text-align: center;
  padding: 0px;
  color: #34a853;
}

.timelineItem > label {
  margin: auto;
  text-align: center;
  padding: 0px;
}

.horizontalLine {
  display: none;

  @media (--viewportSmall) {
    display: inline;
    border-top: 0.1px solid #5f6368;
    width: 100px;
    height: 0;
    margin: 15px 10px;
  }

  @media (--viewportMedium) {
    width: 50px;
  }

  @media (--viewportLarge) {
    width: 70px;
  }

  @media (--viewportLargeWithPaddings) {
    width: 100px;
  }
}

@custom-media --viewportSmall (min-width: 550px);
@custom-media --viewportMedium (min-width: 768px);
@custom-media --viewportLarge (min-width: 1024px);
@custom-media --viewportLargeWithPaddings (min-width: 1128px);
@custom-media --viewportXLarge (min-width: 1921px);
