@import '../../marketplace.css';

.sectionContent {
    width: 100%;
    margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px;
    padding: 0 36px 0 36px;
    margin: 50px auto 50px auto;
    @media (--viewportMedium) {
        max-width: 100%;
    }

    @media (--viewportLarge) {
        max-width: 1050px;
        padding: 0;
    }

    @media (--viewportXLarge) {
        max-width: 1050px;
        padding: 0;
    }
}

.title {
    color: #7bb548;
    font-size: 28px;
    font-weight: 700;
    text-align: center;
    margin: 40px 0;
    @media (--viewportMedium) {
        font-size: 48px;
    }
}

.list {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 50px;
    grid-row-gap: 50px;
    align-items: stretch;
    margin-top: 78px;
    text-align: center;
    font-size: 36px;
    @media (--viewportMedium) {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.listItem {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.listItemText {
    margin: 10px 0 0 0;
    @media (--viewportMedium) {
        margin-top: 28px;
    }
}

.jointext {
    margin: 60px auto 0 auto;
    text-align: center;
    max-width: 750px;
}

.jointext > p {
    font-family: 'Galano Grotesque';
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
}

.buttonWrapper {
    text-align: center;
    margin-top: 45px;
}

.toBuy {
    @apply --marketplaceButtonStyles;
    @apply --SectionHero_animation;
    animation-delay: 0.8s;
}

.joinWrapper {
    margin-top: 0;
    margin-bottom: 38px;
}

.planWrapper {
    margin-top: 0;
    margin-bottom: 87px;
}

.planType {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (--viewportMedium) {
        flex-direction: row;
        justify-content: center;
    }
}

.planType > button {
    max-width: 267px;
    height: 64px;
    width: 100%;
}

.planType > button:not(:last-child) {
    margin-bottom: 16px;
    @media (--viewportMedium) {
        margin-bottom: 0;
        margin-right: 16px;
    }
}

.link {
    display: inline-block;
    width: 100%;
    margin-top: 26px;
    font-family: 'Galano Grotesque';
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    text-decoration: underline;
    color: #000000;
}
.planChooseSuccess {
    margin-bottom: 87px;
    margin-top: 14px;
}

.planChooseSuccess > h4 {
    max-width: 818px;
    width: 100%;
    margin: 0 auto;
    font-family: 'Galano Grotesque';
    font-weight: 700;
    font-size: 23px;
    line-height: 31px;
    text-align: center;
    color: #000000;
}

.accPaymentCont {
    max-width: 1237px;
    width: 100%;
    min-height: 500px;
    background-color: #f9f9f9;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    margin: 0 auto;
    padding: 50px 0;

    margin-bottom: 87px;
}

.selectLandCont {
    max-width: 1237px;
    width: 100%;
}

.testimony {
    font-family: 'Galano Grotesque';
    background-color: #000000;
    padding: 48px 0;
}
.testimonySubHeader {
    font-weight: 700;
    font-size: 18px;
    color: #ffffff;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 4px;
}
.testimonyTitle {
    font-size: 36px;
    font-weight: 700;
    color: #7bb548;
    text-align: center;
    margin-top: 0;
    margin-bottom: 48px;
    @media (--viewportMedium) {
        font-size: 48px;
    }
}

.testimonyList {
    display: grid;
    text-align: center;
    grid-template-columns: 1fr;
    grid-column-gap: 22px;
    grid-row-gap: 40px;
    @media (--viewportMedium) {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.testimonyListItem {
    max-width: 300px;
    width: 100%;
    margin: 0 auto;
}

.testimonyDescription {
    font-family: 'Galano Grotesque';
    margin-top: 24px;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    color: #ffffff;
}

.testimonyPeople {
    font-size: 16px;
    color: #fff;
}

.testimonyPic {
    width: 154px;
    height: 154px;
    object-fit: cover;
    border-radius: 100%;
    object-position: top;
}
.testimonyPeopleName {
    font-family: 'Galano Grotesque';
    color: #fff;
}

.plans {
    position: relative;
    margin-top: 0;
}

.plans .sectionContent {
    max-width: 1320px;
}

.video {
    width: 420px;
    height: 240px;
}

.modalVideo {
    padding: 2px;
    background: white;
    top: 0;
    bottom: 0;
    position: relative;
    margin: auto;
}

.modalVideo > button {
    left: 0;
}

.actionsButtons {
    display: flex;
}

.videoButton_text {
    margin-right: 8px;
}
.videoButton {
    cursor: pointer;
    display: flex;
    padding: 5px 10px;
    align-items: center;
    border: 0;
    text-align: left;
    justify-content: flex-start;
    color: white;
}

.modalButtonContainer {
    display: flex;
    padding-top: 50px;
    justify-content: center;
}

@media (--viewportMedium) {
    .video {
        width: 680px;
        height: 380px;
    }
}
