.BlogHome{
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-areas:
  "first"
  "second"
  "third"
  "fourth";
  @media (--viewportMedium) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas:
      "first first second second"
      "third  third fourth fourth";
  }
  @media (--viewportLarge) {
    grid-template-areas:
    "first first second third"
    "first first second fourth";
  }
}


.BlogHomeItems:nth-of-type(1){
  grid-area: first;
}
.BlogHomeItems:nth-of-type(2){
  grid-area: second;
}
.BlogHomeItems:nth-of-type(3){
  grid-area: third;
}
.BlogHomeItems:nth-of-type(4){
  grid-area: fourth;
}

.BlogHomeItems{
  display:flex;
  flex-direction: column;
  background: white
}

.BlogHomePic{
  width: 100%;
  object-fit: cover;
  flex: 1
}

.BlogHomeTitle{
  margin: 0;
  font-size: 18px;
  font-weight: 800;
}
.BlogHomeLink{
  font-size: 18px;
}
.BlogHomeContent{
  padding: 12px 20px;
  flex: 0 0 120px;
}
