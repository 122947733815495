@import '../../marketplace.css';



:root {
    /*
      These variables are available in global scope through ":root"
      element (<html> tag). Variables with the same names are going to
      overwrite each other if CSS Properties' (PostCSS plugin)
      configuration "preserve: true" is used - meaning that variables
      are left to CSS bundle. We are planning to enable it in the future
      since browsers support CSS Properties already.
   */
  
    --EditListingDescriptionForm_formMargins: {
      margin-bottom: 24px;
  
      @media (--viewportMedium) {
        margin-bottom: 32px;
      }
    }
}

.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 88px 24px 0 24px;
}

.form {
  flex-grow: 1;
}

.title {
  margin-bottom: 19px;

  @media (--viewportLarge) {
    margin-bottom: 38px;
    padding: 1px 0 7px 0;
  }
}

.actionTitle {
    @apply --EditListingDescriptionForm_formMargins;
  }
  
.actionDescription {
    @apply --EditListingDescriptionForm_formMargins;
    flex-shrink: 0;
}

.actionPhoto {
    @apply --EditListingDescriptionForm_formMargins;
}

.actionImageLabel {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

.actionImageInput {
    display: none;
}

.actionPhotoFileName {
    margin-left: 10px;
}
  

.submitButton {
    margin-top: auto;
    margin-bottom: 24px;
    flex-shrink: 0;
    min-width: 270px;
    max-width: 300px;
    height: 50px;
    @media (--viewportLarge) {
      display: inline-block;
      /* width: 241px; */
      /* margin-top: 100px; */
    }
}
  

.error {
    color: var(--failColor);
}

.success {
    color: var(--successColorDark);
}