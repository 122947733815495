.historyList{
  padding: 0;
  list-style: none;
}



.HistItem{
  margin-top: 60px;
}

.historyList_heading{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 10px 0;
}

.HistItem_date{
  margin: 0;
  font-weight: 800;
}

.HistItem_details{
  border: 1px solid #d7d7d7;
  padding: 15px 25px;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
}


.HistItem_details_title{
  font-weight: 800;
  margin: 0
}

.HistItem_details_desc{
  margin-bottom: 0;
}

.HistItem_receiptLink{
  display: inline-block;
}

.cardIcon{
  width: 40px;
  display: inline-block;
  border: 1px solid #ebebeb;
  border-radius: 2px;
  margin-right: 10px;
}

.secretCard{
  display: inline-block;
  opacity: 0.7;
  top: 5px;
  position: relative;
  opacity: 0.5;
}

.emptyListText{
  margin-top: 60px
}
