
  .next {
    text-decoration: none;
    margin-left:55px;
  }
  
  .pagination {
    align-items: center;
    background-color: white;
    height: 115px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin:0;
    padding: 0;
  }
  
  .previous {
    margin-right: 55px;
    text-decoration: none;
  }

  .link {
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 183%;
    color: #000000;
  }

  .disableLink {
    color: #A3A3A3;
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 183%;
  }

  @media screen and (max-width: 850px) {
    .next {
      margin:30px;
    }

    .previous {
      margin: 30px;
    }
}