@import '../../marketplace.css';

.error {
  margin-top: 5px;

  @media (--viewportMedium) {
    margin-top: 4px;
  }
  @media (--viewportLarge) {
    margin-top: 3px;
  }
}

.error {
  color: var(--failColor);
}

.aside {
  box-shadow: none;
}

.asideContent {
  width: 100%;
  display: flex;
  flex-direction: row;

  @media (--viewportLarge) {
    flex-direction: column;
    border-bottom: none;
  }
}

.avatar {
  margin: 0 auto;
  flex-shrink: 0;
  text-align: center;
  display: flex;
  justify-content: center;

  @media (--viewportLarge) {
  }
}

.mobileHeading {
  flex-shrink: 0;
  margin: 47px 0 0 0;

  @media (--viewportMedium) {
    margin: 49px 0 0 0;
  }
  @media (--viewportLarge) {
    display: none;
  }
}

.editLinkMobile {
  margin-top: 17px;

  /* Pull the link to the end of the container */
  margin-left: auto;

  @media (--viewportMedium) {
    margin-top: 20px;
  }
  @media (--viewportLarge) {
    display: none;
  }
}

.editLinkDesktop {
  display: none;

  @media (--viewportLarge) {
    display: inline;
  }
}

.desktopHeading {
  display: none;
  text-align: center;
  @media (--viewportLarge) {
    display: block;
    margin: 2px 0 24px 0;
  }
}

.bio {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  margin: 5px 0 24px 0;

  @media (--viewportMedium) {
    margin: 4px 0 51px 0;
  }
  @media (--viewportLarge) {
    margin: 0 0 56px 0;
    max-width: 600px;
  }
}

.listingsContainer {
  @apply --clearfix;
  padding-bottom: 23px;

  @media (--viewportMedium) {
    padding-bottom: 22px;
  }
}

.listingsTitle {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 48px;
    margin-bottom: 8px;
  }
  @media (--viewportLarge) {
    margin-top: 58px;
    margin-bottom: 8px;
  }
}

.withBioMissingAbove {
  /* Avoid extra borders in mobile when bio is missing */
  border-top: none;

}

.listings {
  margin: 0;
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-columns: 1fr 1fr;
  @media (--viewportMedium) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.listing {
  width: 100%;
}

.mobileReviewsTitle {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);
  margin: 24px 0 26px 0;
}

.mobileReviews {
  margin-bottom: 36px;
}

.desktopReviews {
  margin-top: 28px;

  @media (--viewportMedium) {
    margin-bottom: 48px;
  }

  @media (--viewportLarge) {
    max-width: 600px;
    margin-bottom: 0;
  }
}

.desktopReviewsTitle {
  @apply --marketplaceH3FontStyles;
  margin-bottom: 0;
}

.desktopReviewsTabNav {
  margin-bottom: 32px;
}


.content {
  @media (--viewportMedium) {
    margin: 32px auto 0 auto;
    max-width: 564px;
  }

  @media (--viewportLarge) {
    margin: 0;
  }
}


.contentContainer {
  width: 100%;
  padding: 0 36px 0 36px;
  @media (--viewportMedium) {
    max-width: 1056px;
    margin: 0 auto;
    padding: 0 24px;
    position: relative;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px;
    margin: 42px auto;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: none;
}

.title {
  margin-top: 8px;
  margin-bottom: 19px;

  @media (--viewportMedium) {
    margin-bottom: 48px;
  }
}

.contentSection{
  text-align: center;
  margin: 60px 0;
}

.titleSection{
  font-size: 28px;
  text-align: center;
}

.photoShow{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  @media (--viewportMedium) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.photoShow img{
  width: 100%;
  height: 100px;
  object-fit: cover;
  @media (--viewportMedium) {
    height: 250px;
  }
}

.graySection{
  padding: 40px 0;
  background-color:#F5F5F5
}

.video{
  width: 100%;
  max-width: 640px;
  margin: 30px
}


.profilePictureWrapper {
  width: 200px;
  height: auto;
  max-height: 100px;
  border-radius: 0;
}

.profilePicture {
  width: 100%;
  height: 100%;
  max-height: inherit;
  object-fit: contain;
  object-position: left top;
  border-radius: inherit;
}