@import '../../marketplace.css';

.hero {
    position: relative;
}

.hero:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(-10deg, rgba(0, 0, 0, 0.1) 25%, rgba(0, 0, 0, 0.75));
    z-index: -1;
}

.hero:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(-90deg, rgba(0, 0, 0, 0.1) 25%, rgba(0, 0, 0, 0.5));
    z-index: -1;
}

.image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: -2;
}

.title {
    font-size: 36px;
    margin-top: 0;
    margin-bottom: 42px;
    line-height: 32px;
    font-weight: 700;
    color: white;
    @media (--viewportMedium) {
        font-size: 59.4px;
        line-height: 80px;
    }
}

.heroButton {
    @apply --marketplaceButtonStyles;
    @apply --SectionHero_animation;
    margin-right: 10px;
    font-size: 18px;
    animation-delay: 0.8s;
    @media (--viewportMedium) {
        font-size: 26px;
    }
}
.heroHeadTitle {
    color: white;
}

.video {
    width: 420px;
    height: 240px;
}

.modalVideo {
    padding: 2px;
    background: white;
    top: 0;
    bottom: 0;
    position: relative;
    margin: auto;
}

.modalVideo > button {
    left: 0;
}

.actionsButtons {
    display: flex;
}

.videoButton_text {
    margin-right: 8px;
}
.videoButton {
    cursor: pointer;
    display: flex;
    padding: 5px 10px;
    align-items: center;
    border: 0;
    text-align: left;
    justify-content: flex-start;
    color: white;
}

@media (--viewportMedium) {
    .video {
        width: 680px;
        height: 380px;
    }
}

.subtitle {
    max-width: 650px;
    color: white;
    font-weight: 700;
    font-size: 14.8521px;
    line-height: 20px; 
    @media (--viewportMedium) {
        font-size: 19px;
        line-height: 26px; 
    }
}

.subtitleFriends {
    font-family: 'Galano Grotesque';
    @media (--viewportMedium) {
        max-width: 424px;
        font-size: 19px;
        font-weight: 700;
        line-height: 26px;
    }
}

.heroBig {
    height: 340px;
    padding-top: 100px;
    @media (--viewportMedium) {
        height: 430px;
        padding-top: 160px;
    }
}

.heroMedium {
    height: 300px;
    padding-top: 160px;
}

.sectionContent {
    width: 100%;
    padding: 0 36px 0 36px;

    @media (--viewportMedium) {
        max-width: 100%;
    }

    @media (--viewportLarge) {
        max-width: 1128px;
        margin: auto;
    }

    @media (--viewportXLarge) {
        max-width: 1056px;
    }
}
