@import '../../marketplace.css';

.root {
  background-color: var(--matterColorLight);
}
.content {
  width: calc(100% - 48px);
  margin: 12px 24px 24px 24px;

  @media (--viewportMedium) {
    max-width: 565px;
    margin: 0 auto 56px auto;
  }
}

.headingContainer {
  display: flex;
  flex-direction: row;
  align-items: center
}

.heading {
  margin-right: 24px;
  hyphens: auto;
}

.profileLink {
  @apply --marketplaceButtonStylesHiddenSmall;
  margin-left: auto
}
