.landScapeSelectCont {
    padding: 10px;
}
.landScapeSelectCont > h3 {
    font-family: 'Galano Grotesque';
    font-weight: 700;
    font-size: 30px;
    line-height: 93.99%;
    text-align: center;
    color: #7bb548;
}
.landScapeSelectCont > .chooseLS {
    font-family: 'Galano Grotesque';
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #000000;
}

.landScapeSelectTag {
    position: relative;
    max-width: 606px;
    width: 100%;
    margin: 0 auto;
}

.landScapeSelectTag > select {
    border: 3px solid #000000;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 12.5px 45px;
    font-family: 'Galano Grotesque';
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: #000000;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: none;
    @media screen and (max-width:530px){
        padding: 12.5px 70px 12.5px 20px;
    }
}

.landScapeSelectTag > .customArrow {
    display: block;
    width: 75px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    border-left: 3px solid #000000;
    pointer-events: none;
}

.customArrow::before {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    border-right: 3px solid black;
    border-top: 3px solid black;
    left: 50%;
    top: 50%;

    transform: translate(-50%, -50%) rotate(135deg);
}

.landScapeDetail {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;

    @media (--viewportXLarge) {
        flex-direction: row;
    }
}

.landScapeImgCont > img {
    max-width: 580px;
    width: 100%;
    margin-bottom: 30px;

    @media (--viewportXLarge) {
        margin-bottom: 0;
        margin-right: 30px;
    }
}

.landScapeInfo > h4 {
    max-width: 458px;
    width: 100%;
    font-family: 'Galano Grotesque';
    font-weight: 700;
    font-size: 25px;
    line-height: 34px;
    color: #000000;
    margin: 0;
}
.landScapeInfo > p {
    max-width: 566px;
    width: 100%;
    font-family: 'Galano Grotesque';
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;

    color: #000000;
}

.landScapeInfo > .landScapeArea {
    max-width: 555px;
    width: 100%;
    font-size: 25px;
    line-height: 34px;

    color: #000000;
}

.landScapeSuppImp > h2 {
    font-family: 'Galano Grotesque';
    font-weight: 700;
    font-size: 35px;
    line-height: 47px;
    text-align: center;
    color: #000000;
    margin-top: 86px;
    margin-bottom: 35px;
}

.impactStrip {
    max-width: 300px;
    width: 100%;
    margin: 0 auto;
    background: #000000;
    border-radius: 12px;

    display: flex;
    flex-direction: column;
    margin-bottom: 72px;
    padding: 43px 46px 22px;

    @media (--viewportMedium) {
        flex-direction: row;
        max-width: 788px;
        width: 100%;
    }
}

.impactStrip > div:not(:last-child) {
    border-bottom: 1px solid #fff;
    margin-bottom: 34px;
    @media (--viewportMedium) {
        border: 0;
        border-right: 1px solid #fff;
        margin: 0;
        margin-right: 34px;
    }
}

.impactStrip > div > h4 {
    font-family: 'Galano Grotesque';
    font-weight: 700;
    font-size: 26px;
    line-height: 25px;
    color: #ffffff;
    margin-bottom: 0;
    margin-top: 0;
}

.impactStrip > div > p {
    font-family: 'Galano Grotesque';
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    color: #ffffff;
    margin-top: 4px;
}

.trees > p {
    margin-top: 20px !important;
}

.actionBtns {
    display: flex;
    justify-content: center;
}
.actionBtns > button {
    max-width: 267px;
    padding: 17px 20px;
    width: 100%;
    border-radius: 8px;
}
.actionBtns > button:not(:last-child) {
    background-color: #f29509;
    margin-right: 24px;
}

@custom-media --viewportXLarge (min-width: 1440px);
