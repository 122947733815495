@custom-media --viewportLargeWithPaddings (max-width: 1350px);
@custom-media --viewportLarge (max-width: 1240px);
@custom-media --viewportSmall (max-width: 440px);

.chooseGuardianRoleCard {
    width: 377px;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 15px 15px 50px;
    transition: 0.5s;
    
    @media (--viewportLarge) {
        margin: auto;
    }
}
.chooseGuardianRoleCard img {
    width: 100%;
}

.chooseGuardianRoleCard:hover {
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.chooseGuardianRoleCardSubmitted {
    background: #f9f9f9;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    height: 545px;
    padding-left: 15px;
    padding-right: 15px;
}
.guardianRole {
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-weight: 700;
    font-size: 23.1858px;
    margin: 0;
    line-height: 26px;
    letter-spacing: 0.5px;
}
.guardianRoleDesc {
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    margin: 0;
    text-align: center;
}
.checkIcon {
    margin-right: 20px;
}
.expressInterestButton {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.expressInterestButtonSubmitted {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.checkedIcon {
    display: flex;
    justify-content: center;
}

.checkedIcon img {
    width: 60px;
    height: 60px;
}
