@import '../../marketplace.css';

.root {
    @apply --marketplaceModalFormRootStyles;

    justify-content: flex-start;

    @media (--viewportMedium) {
        justify-content: space-between;
    }
}

.name {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;

    @media (--viewportMedium) {
        margin-top: 32px;
    }
}

.firstNameRoot {
    width: calc(34% - 9px);
}

.lastNameRoot {
    width: calc(66% - 9px);
}

.password {
    @apply --marketplaceModalPasswordMargins;
}

.bottomWrapper {
    @apply --marketplaceModalBottomWrapper;
}

.bottomWrapperText {
    @apply --marketplaceModalBottomWrapperText;
}

.termsText {
    @apply --marketplaceModalHelperText;
}

.termsLink {
    @apply --marketplaceModalHelperLink;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}

.userType {
    margin-top: 0;
    margin-bottom: 24px;
    @media (--viewportMedium) {
        margin-bottom: 32px;
    }
}

.googleSignUp {
    width: 100%;
    margin: 20px auto;
    text-align: center;
}
.googleSignUp h5 {
    font-family: 'Galano Grotesque';
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #000000;
}

.googleButton {
    max-width: 316px;
    width: 100%;
    padding: 15px;
    background-color: white;
    color: #7d7d7d;
    box-shadow: 3px 3px 12px -7px rgb(0 0 0 / 63%);
    cursor: pointer;
    border-radius: 5px !important;
    font-size: 15px !important;
    border: none;
    font-weight: bold !important;
    margin-top: 21px;
    margin-bottom: 34px;
    text-align: left;
}

.googleSignUp > button {
    width: 100%;
    border-radius: 15px;
}
