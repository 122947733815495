@import '../../../../../marketplace.css';

.btn{
    display: flex;
    flex-direction: row;
    gap:10px;
    flex: 1 1 40px;
    margin: 12px;
}



.unsubscribeButton{
    margin-top: 12px;
    @apply --marketplaceButtonStylesSecondarySmall;
  }
  
  .unsubscribeButtonModal{
    @apply --marketplaceButtonStylesSecondary;
    width: 100%;
    @media (--viewportMedium) {
      width: auto;
    }
  }
  
  .unsubscribeButtonCancel{
    @apply --marketplaceButtonStylesHidden;
    width: 100%;
    margin-bottom: 20px;
    @media (--viewportMedium) {
      margin-bottom: 0;
      width: auto;
    }
  
  }
  .modalUnsuscribeText{
    margin-top: 30px
  }
  .modalButtons{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    @media (--viewportMedium) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
  
