@import '../../marketplace.css';

.root {
    @apply --marketplaceModalFormRootStyles;

    justify-content: flex-start;

    @media (--viewportMedium) {
        justify-content: space-between;
    }
}

.password {
    @apply --marketplaceModalPasswordMargins;
}

.bottomWrapper {
    @apply --marketplaceModalBottomWrapper;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
    @apply --marketplaceModalBottomWrapperText;
}

.recoveryLink {
    @apply --marketplaceModalHelperLink;
}

.recoveryLinkInfo {
    @apply --marketplaceModalHelperText;
}

.googleLogIn {
    width: 100%;
    margin: 20px auto;
    text-align: center;
}
.googleLogIn h5 {
    font-family: 'Galano Grotesque';
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #000000;
}

.googleButton {
    max-width: 316px;
    width: 100%;
    padding: 15px;
    background-color: white;
    color: #7d7d7d;
    box-shadow: 3px 3px 12px -7px rgb(0 0 0 / 63%);
    cursor: pointer;
    border-radius: 5px !important;
    font-size: 15px !important;
    border: none;
    font-weight: bold !important;
    margin-top: 21px;
    margin-bottom: 34px;
    text-align: left;
}

.googleLogIn > button {
    width: 100%;
    border-radius: 15px;
}
