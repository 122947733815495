@import '../../marketplace.css';

.root {

  width: 100%;
  background-color: white;
  @media (--viewportMedium) {
  }

  @media (--viewportLarge) {
    border-top: none;
  }
}

/* Wrapper to limit the top border in desktop
 * view from the outermost paddings*/
.topBorderWrapper {
  color: #AAAAAA;
  background-color: #041206;
  @media (--viewportLarge) {
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: var(--matterColorNegative);
  }
}
.content *{
  font-size: 18px
}
.content {
  display: flex;
  padding: 36px;
  flex-direction: column;
  width: 100%;

  @media (--viewportMedium) {
    margin: 0 auto;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
  }
}

/* Mobile only social media links */
.someLiksMobile {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.icon {
  display: inline-block;
  width: 20px;
  height: 24px;
  margin-right: 12px;
  text-align: center;
}

/* Footer links */
.links {
  /* Layout */
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.socialIcon{
 font-size: 30px;
 color: #AAA;
 margin-right: 5px
}
/* Desktop: logo and organization stuff */
.organization {
  flex-shrink: 0;
}

.logoLink {
  display: block;
  margin-bottom: 14px;
}

.logo {
  object-fit: contain;
  object-position: left center;
  width: 100%;
  height: 45px;
}

.organizationInfo {
}

.organizationDescription {
  @apply --marketplaceTinyFontStyles;
}

.organizationCopyright {
  @apply --marketplaceTinyFontStyles;
  margin-top: 32px;
}

.mailLink{
  color: white;
  display: inline-block;
  margin-bottom: 20px;
}

.copyrightLink {
  color: var(--matterColor);
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

/* Desktop and Mobile: links to more information */

.list {
  margin-top: 0;
  margin-bottom: 0;
}

.listItem {
  min-height: 24px;
  margin-bottom: 6px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.link {
  /* Font */
  @apply --marketplaceH4FontStyles;
  line-height: 20px;
  color: var(--matterColor);
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 12px;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}


/* Desktop and Mobile: searches - second column */
/* This is not always visible */
.searchesExtra {
  order: 4;
  flex-basis: 165px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }
}

/* Desktop: extra links (social media links and legal links) */
.extraLinks {
  flex-shrink: 0;
  order: 5;
  display: none;
  flex-basis: 109px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    margin-right: 12px;
  }
  @media (--viewportLarge) {
    margin-right: 0;
  }
}

.legalMatters {
  /* Get extra space from parent element (which is using flexbox) */
  flex-grow: 1;

  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 12px;
}

.tosAndPrivacy {
  margin-top: 0;
  margin-bottom: 0;
}

.legalLink,
.privacy,
.terms {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* Mobile: copyright mark and legal links for mobile */
.copyrightAndTermsMobile {
  /* Positioning */
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  /* Dimensions */
  height: 60px;
  padding: 0 24px 18px 24px;

  /* Layout */
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    display: none;
  }
}

.organizationCopyrightMobile {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  /* Tell parent component how much space this one prefers */
  flex-basis: 172px;

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

.tosAndPrivacyMobile {
  flex-basis: 172px;
}

.privacy {
  margin-right: 24px;
}

.infoLinksItem{
  font-size: 18;
  color: #AAA;
  margin-bottom: 8px
}

.bLogoContainer{
  margin: 30px 0 0 0;
}

.bLogoContainerPng{
  display: flex;
  align-items: center;
}

.logosFooter{
  max-width: 120px;
  opacity: 0.5;

  @media (--viewportMedium) {
    max-width: 90px;
  }
}

.logosFooter:first-of-type{
  margin-right: 20px;
}

.bLogoText{
  font-size: 16px;;
}

.titleColumn{
  margin-top:0
}


.allies{
  text-align: center;
  align-items: center;
  
  @media (--viewportMedium) {
    text-align: left;
    display: flex;
  }
}

.alliesTitle{
  margin: 0;
  white-space: nowrap;
  font-size: 24px;
  color: var(--marketplaceColor)
}

.alliesPic{
  width: 50%;
  margin: 20px 32px;
  min-width: 0;
  max-width: 110px;
  @media (--viewportMedium) {
    width: 100%;
    margin: 0 32px;
  }
}

.legalContainer{
  padding-top: 14px;
  border-top: 1px solid  rgba(255, 255, 255, 0.12);
  text-align: center;
}

.legalTextLink{
  color: white;
  opacity: 0.5;
  font-size: 12px;
  padding: 10px;
}
.alliesPic:nth-of-type(2){
  @media (--viewportMedium) {
    max-width: 110px;
  }
}
.alliesPic:nth-of-type(3){
  max-width: 60px
}

.logosContainer{
  padding: 36px;
  width: 100%;

  @media (--viewportMedium) {
    margin: 0 auto;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
  }
}

.fieldContainer{
  margin: 0 0 18px 0;
}

.suscriptionInput{
  display: inline-block;
}

.errorlabel{
  color: #ff8080;
  font-size: 14px;
}
