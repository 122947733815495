.likelisting{
  display: flex;
  align-items: center;
}
.logoimage{
  height: 35px;
}
.buttonLogo{
  width: 50px;
  height: 50px;

  border-radius: 100%;
  border: 1px solid #e9e9e9;

  transition: all 0.2s ease-out;
  transform: scale(1);
  outline: none;
  cursor: pointer;

}

.buttonLogo:before{
  content: '';
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 1px solid #2DC000;
  animation: logoanim 0.1s ease-out;
  animation-fill-mode: forwards;
}

.buttonLogo:hover{
  transition: all 0.2s ease-out;
  transform: scale(1.1);
}

.buttonLogo:active{
  transition: all 0.2s ease-out;
  transform: scale(.9);
  border-color: #2DC000;
}

.buttonLogo:active::before{
  display: block;
}


.counter{
  margin-left: 10px;
  font-weight: 800;
  color: #2DC000;
}

@keyframes logoanim{
  0%{
    transform: scale(1);
    opacity: 1;
  }
  100%{
    transform: scale(1.8);
    opacity: 0;
  }
}
