.paymentSuccessContainer {
  max-width: 1237px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 87px;
}

.paymentSuccessContainer > h3 {
  font-family: 'Galano Grotesque';
  font-weight: 700;
  font-size: 30px;
  line-height: 93.99%;
  text-align: center;
  color: #7bb548;
  margin-bottom: 40px;
}

.actionBoxContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 90%;
  width: 100%;
  margin: 0 auto;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.actionBox {
  max-width: 500px;
  width: 100%;
  padding: 10px;
  text-align: center;
  margin:auto;
}

.actionBox > p {
  font-family: 'Galano Grotesque';
  min-height: 70px;
}

.actionBox button {
  cursor: pointer;
}

.fbButton {
  padding: 10px 25px;
  background-color: #3b5a98;
  cursor: pointer;
  color: white;
  border-radius: 5px;
  font-size: 15px;
  border: none;
  height: 50px;
  font-family: 'Galano Grotesque';
  font-weight: bold;
  width: 100%;
}

.socialIcons {
  margin-right: 35px;
  font-size: 27px;
}

.orangeButton {
  border: none;
  background: #dc7f28;
  color: white;
  height: 50px;
  border-radius: 5px;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}
